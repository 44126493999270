import React from "react";

export const Link = (props) => {
  return (
    <>
      {" "}
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {props.label} {props.children}
      </a>{" "}
    </>
  );
};

export const Image = (props) => {
  const defaultAttributes = tagAttributes(props);

  return (
    <img
      src={props.src}
      alt=""
      {...defaultAttributes}
      className={`ax-image ${props.className}`}
    />
  );
};

function tagAttributes(props) {
  return (({ className, title, role, onClick, style }) => ({
    className,
    title,
    role,
    onClick,
    style,
  }))(props);
}
