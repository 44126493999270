export const CarbonSequestrationKey = ({ showNotEstimated }) => {
  const styles = {
    colorSwab: {
      marginRight: ".5em",
      padding: "0 1em 0 1em",
      borderRadius: ".25rem",
      boxSizing: "border-box",
    },
    colorSwabWrapper: {
      marginTop: ".25em",
    },
  };
  return (
    <div className="glob-planner-numberkey-container" style={{ margin: ".25em" }}>
      <div style={styles.colorSwabWrapper}>
        <span
          style={{ ...styles.colorSwab, backgroundColor: "blue", border: "1px solid blue" }}
        />
        Carbon Sequestration, Greenhouse Gas Reduction (+)
      </div>
      <div style={styles.colorSwabWrapper}>
        <span
          style={{ ...styles.colorSwab, backgroundColor: "red", border: "1px solid red" }}
        />
        Carbon Loss, Greenhouse Gas Increase (-)
      </div>
      {showNotEstimated && (
        <div style={styles.colorSwabWrapper}>
          <span
            style={{
              ...styles.colorSwab,
              border: "1px solid #92a9b6",
              boxSizing: "border-box",

              background: "transparent",
              padding: "0 .68em 0 .68em",
            }}
          >
            --
          </span>
          Not Estimated in associated Conservation Practice
        </div>
      )}
    </div>
  );
};

export const TonnesCo2ePerYearLong = () => (
  <>
    Tonnes CO<sub>2 </sub> equivalent per year
  </>
);
export const TonnesCo2ePerYearShort = () => (
  <>
    T CO<sub>2</sub>e / yr
  </>
);
export const TonnesCo2ePerYearMedium = () => (
  <>
    Tonnes CO<sub>2</sub>e / yr
  </>
);
export const TonnesCo2ePerYearString = "Tonnes CO2e per year";
