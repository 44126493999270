import { call, put } from "redux-saga/effects";

import { get } from "../utils/api";
import { LOCATION_DATA } from "../constants/api";

import { clearCoefficients } from "../reducers/conservationPractice";
import { receiveLocationData } from "../reducers/location";
import { recalculateSelected } from "./conservationPractice";

export function* setLocation(action) {
    if (action.payload.lat && action.payload.lon) {
        //clear the results values and location data
        yield put(clearCoefficients());
        yield put(receiveLocationData({}));

        try {
            const locationData = yield call(get, LOCATION_DATA, {
                lat: action.payload.lat,
                lon: action.payload.lon,
            });
            yield put(receiveLocationData(locationData));
            yield call(recalculateSelected);
        } catch (error) {
            console.warn("Error setting location: ", error);
        }
    }
}
