import React from "react";

import LocationSearchbar from "./LocationSearchbar";
import AddLocationButton from "./AddLocationButton";

import "./MapToolbar.css";

export default function MapToolbar(props) {
  return (
    <div id="mapToolbar">
      <LocationSearchbar />
      {/* if they want a button for "drop a pin" instead of just clicking at will, we can use this AddLocationButton */}
      {/* <AddLocationButton /> */}
    </div>
  );
}
