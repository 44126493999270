import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { locationReducer } from "../reducers/location";
import { mapReducer } from "../reducers/map";
import { baselineReducer } from "../reducers/baseline";
import { conservationPracticeReducer } from "../reducers/conservationPractice";

export const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        location: locationReducer,
        map: mapReducer,
        baseline: baselineReducer,
        conservationPractice: conservationPracticeReducer,
    },
    middleware: (getDefaultMiddleware) => {
        //replace thunk with sagas for async tasks
        return getDefaultMiddleware({ thunk: false }).prepend(sagaMiddleware);
    },
});
