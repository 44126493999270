import React from "react";

import { sagaMiddleware } from "./store/store.js";
import { listBaseline } from "./sagas/baseline.js";
import { listConservationPractices } from "./sagas/conservationPractice.js";
import { fetchCountriesLayer, fetchUSALayer } from "./sagas/map.js";
import { rootSaga } from "./sagas/root.js";

// Routes
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import routes from "./routes/routes";

// Home page
import HomePage from "./views/admin/HomePage.jsx";

const App = () => {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (routesList) =>
    routesList.map((route) => {
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key ? route.key : route.route}
          />
        );
      }

      return null;
    });

  return (
    <Routes>
      {getRoutes(routes)}
      <Route path="/home" element={<HomePage />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
};

export default App;

sagaMiddleware.run(rootSaga); //add saga listeners for all reducer actions
sagaMiddleware.run(fetchUSALayer);
sagaMiddleware.run(fetchCountriesLayer);
sagaMiddleware.run(listBaseline);
sagaMiddleware.run(listConservationPractices);
