import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import PlannerGlobalPage from "../PageLayout/PlannerGlobalPage";
import { useState } from "react";
import { CONTACT_US } from "../../constants/api";

// TODO: Check mobile compatibility

const ContactUs = () => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  // Handle user submit
  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(CONTACT_US, {
      method: "POST",
      body: JSON.stringify({
        name: userName,
        email: userEmail,
        subject: subject,
        message: message,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "Error") {
          console.log(data.message);
        }
      });

    setUserName("");
    setUserEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <PlannerGlobalPage>
      <Box
        px={5}
        py={3}
        minHeight="89vh"
        component="form"
        onSubmit={handleSubmit}
        display="flex"
        flexDirection="column"
        width="1200px"
        maxWidth="100vw"
      >
        <Typography variant="h2" fontSize="2rem">
          Contact Us
        </Typography>
        <Typography paragraph>
          We would love to hear from you! Please feel free to provide any issues, suggestions,
          or feedback here and we will respond as quickly as possible.
        </Typography>
        <Grid>
          {/* Name */}
          <Grid py={1} maxWidth="75ch">
            <Typography variant="h4">Name</Typography>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
            />
          </Grid>
          {/* Email */}
          <Grid py={1} maxWidth="75ch">
            <Typography variant="h4">Email</Typography>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
            />
          </Grid>
          {/* Subject */}
          <Grid py={1} maxWidth="100ch">
            <Typography variant="h4">Subject</Typography>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            />
          </Grid>
          {/* Message */}
          <Grid py={1} maxWidth="100ch">
            <Typography variant="h4">Message</Typography>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={15}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": { padding: "0px" },
              }}
            />
          </Grid>
        </Grid>
        <Grid py={2}>
          <Button type="submit" variant="contained" sx={{ maxWidth: "200px" }}>
            Submit
          </Button>
        </Grid>
      </Box>
    </PlannerGlobalPage>
  );
};
export default ContactUs;
