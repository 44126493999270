import { all, call, put, select } from "redux-saga/effects";

import { get, post } from "../utils/api";
import {
    LIST_CONSERVATION_PRACTICES,
    LIST_AGROFORESTRY_PRACTICES,
    RESULTS,
} from "../constants/api";
import {
    getFarmTypeId,
    getLocation,
    getConservationList,
    getConservationSelectedIds,
    getAgroforestryList,
} from "../store/selectors";
import {
    clearCoefficients,
    receiveConservationPracticeList,
    receiveAgroforestryPracticeList,
    receiveResult,
} from "../reducers/conservationPractice";

export function* listConservationPractices() {
    try {
        // Fetch list of soil carbon conservation practices
        const soilCdata = yield call(get, LIST_CONSERVATION_PRACTICES);

        // we want to take the data from the backend in its "flat" form and put
        // it into a structure that's easier to filter
        const conservationPractices = [];

        for (let item of soilCdata) {
            //if the practice hasn't been added to the list, create the basic structure
            if (!conservationPractices.some((i) => i.id === item.practice.id)) {
                conservationPractices.push({
                    id: item.practice.id,
                    name: item.practice.name,
                    ipccEquation: item.ipccEquation,
                    emissionSubcategory: "soilCarbon",
                    baselineLandUseClass: item.baselineLandUseClass,
                    scenarioLandUseClass: item.scenarioLandUseClass,
                    climateRegions: [],
                });
            }

            // get the matching practice from the list
            let conservationPractice = conservationPractices.find(
                (s) => s.id === item.practice.id
            );

            // if the practice doesn't have a matching climate zone entry, create it
            if (
                !conservationPractice.climateRegions.some(
                    (cr) => cr.id === (item.climateRegion ? item.climateRegion.id : null)
                )
            ) {
                conservationPractice.climateRegions.push({
                    id: item.climateRegion ? item.climateRegion.id : null,
                    name: item.climateRegion ? item.climateRegion.name : null,
                    farmTypes: [],
                });
            }

            //get the matching climate zone entry
            let climateRegion = conservationPractice.climateRegions.find(
                (cr) => cr.id === (item.climateRegion ? item.climateRegion.id : null)
            );

            //add the farm type entry with its values
            climateRegion.farmTypes.push({
                id: item.farmType ? item.farmType.id : null,
                name: item.farmType ? item.farmType.name : null,
                baselineInputClass: item.baselineInputClass,
                baselineManagementClass: item.baselineManagementClass,
                scenarioInputClass: item.scenarioInputClass,
                scenarioManagementClass: item.scenarioManagementClass,
            });
        }

        // Now fetch list of agroforestry biomass practices
        const biomassData = yield call(get, LIST_AGROFORESTRY_PRACTICES);

        const agroforestryPractices = [];

        for (let item of biomassData) {
            // Check to see if the condensed list already has this practice
            if (!agroforestryPractices.some((i) => i.id === item.practice.id)) {
                agroforestryPractices.push({
                    id: item.practice.id,
                    name: item.practice.name,
                    emissionSubcategory: "biomassCarbon",
                    baselineLandUse: item.baselineLandUse.id,
                    climateRegions: [],
                });
            }

            // Now find the matching practice
            let agroforestryPractice = agroforestryPractices.find(
                (p) => p.id === item.practice.id
            );

            // Check to see if the practice already has an entry for this climate region
            if (!agroforestryPractice.climateRegions.some((cr) => cr.id === item.climate.id)) {
                agroforestryPractice.climateRegions.push({
                    id: item.climate.id,
                    name: item.climate.name,
                    farmTypes: [],
                });
            }

            // Now find the matching climate region
            let climateRegion = agroforestryPractice.climateRegions.find(
                (cr) => cr.id === item.climate.id
            );

            // Check to see if the climate region already has an entry for the farm type
            if (!climateRegion.farmTypes.some((ft) => ft.id === item.farmType.id)) {
                climateRegion.farmTypes.push({
                    id: item.farmType ? item.farmType.id : null,
                    name: item.farmType ? item.farmType.name : null,
                    agroforestryRegions: [],
                });
            }

            // Finally we find the matching region
            let farmType = climateRegion.farmTypes.find((ft) => ft.id === item.farmType.id);

            // Check to see if the farm type already has a matching region
            if (
                !farmType.agroforestryRegions.some(
                    (ar) => ar.id === item.agroforestryRegion.id
                )
            ) {
                farmType.agroforestryRegions.push({
                    id: item.agroforestryRegion.id,
                    name: item.agroforestryRegion.name,
                    baselineLandUse: item.baselineLandUse,
                    agroforestrySystem: item.agroforestrySystem,
                    baselineLandUseClass: item.baselineLandUseClass,
                    baselineInputClass: item.baselineInputClass,
                    baselineManagementClass: item.baselineManagementClass,
                    scenarioLandUseClass: item.scenarioLandUseClass,
                    scenarioInputClass: item.scenarioInputClass,
                    scenarioManagementClass: item.scenarioManagementClass,
                    totalBiomass: item.totalBiomass,
                });
            }
        }

        yield put(receiveConservationPracticeList(conservationPractices));
        yield put(receiveAgroforestryPracticeList(agroforestryPractices));
    } catch (error) {
        console.warn("Error fetching conservation practices:", error);
    }
}

export function* selectConservationPractice(action) {
    const conservationPracticeList = yield select(getConservationList);
    let conservationPractice = conservationPracticeList.find((c) => c.id === action.payload);

    const agroforestryPracticeList = yield select(getAgroforestryList);
    if (!conservationPractice) {
        conservationPractice = agroforestryPracticeList.find((ap) => ap.id === action.payload);
    }

    yield call(getResults, conservationPractice);
}

export function* recalculateSelected() {
    yield put(clearCoefficients());

    const location = yield select(getLocation);

    if (location.climate && location.soil) {
        const conservationPracticeList = yield select(getConservationList);
        const agroforestryPracticeList = yield select(getAgroforestryList);
        const selectedIds = yield select(getConservationSelectedIds);

        // Add soil carbon practices to list
        const selectedPractices = conservationPracticeList.filter((c) =>
            selectedIds.includes(c.id)
        );

        agroforestryPracticeList
            .filter((ap) => selectedIds.includes(ap.id))
            .forEach((ap) => selectedPractices.push(ap));

        yield all(selectedPractices.map((c) => call(getResults, c)));
    }
}

function* getResults(conservationPractice) {
    const location = yield select(getLocation); // Selected location

    let climateRegion = conservationPractice.climateRegions.find(
        (cr) => cr.id === location.climate.id || cr.id === null
    ); // Climate region

    const farmTypeId = yield select(getFarmTypeId); // Farm Type ID
    let farmType = climateRegion.farmTypes
        ? climateRegion.farmTypes.find((f) => f.id === farmTypeId || f.id === 1502)
        : null; // Farm type info

    // Result values
    var error = null;
    var soilC = 0;
    var biomassC = 0;
    var baselineLandUseClass = null;
    var baselineInputClass = null;
    var baselineManagementClass = null;
    var scenarioLandUseClass = null;
    var scenarioInputClass = null;
    var scenarioManagementClass = null;

    // Get Soil Carbon sequestration value from backend
    if (!climateRegion) {
        error = "No data for this climate";
    } else {
        // TODO standardize these names, you call them "Conservation Practices"/"Agroforestry Practices" in some places and "Soil Carbon"/"Biomass Carbon" in others
        const soilCpracticeList = yield select(getConservationList);
        const biomassCpracticeList = yield select(getAgroforestryList);

        // Custom return for practices that have both emission subcategories TODO doing things this way gives me heartburn, need to find a more efficient way of sorting
        if (
            soilCpracticeList.some((sc) => sc.name === conservationPractice.name) &&
            biomassCpracticeList.some((bc) => bc.name === conservationPractice.name)
        ) {
            const soilCpractice = soilCpracticeList.find(
                (sc) => sc.name === conservationPractice.name
            );
            const tempClimateRegion = soilCpractice.climateRegions.find(
                (cr) => cr.id === location.climate.id || cr.id === null
            );
            const tempFarmType = tempClimateRegion.farmTypes
                ? tempClimateRegion.farmTypes.find((f) => f.id === farmTypeId || f.id === 1502)
                : null; // Farm type info
            const payload = {
                climate: location.climate,
                soil: location.soil,
                conservationPractice: {
                    id: soilCpractice.id,
                    name: soilCpractice.name,
                    baselineLandUseClass: soilCpractice.baselineLandUseClass,
                    baselineInputClass: tempFarmType.baselineInputClass,
                    baselineManagementClass: tempFarmType.baselineManagementClass,
                    scenarioLandUseClass: soilCpractice.scenarioLandUseClass,
                    scenarioInputClass: tempFarmType.scenarioInputClass,
                    scenarioManagementClass: tempFarmType.scenarioManagementClass,
                },
            };

            try {
                const result = yield call(post, RESULTS, payload);
                soilC = result;
            } catch ({ status, message }) {
                soilC = message.message;
            }

            const biomassCpractice = biomassCpracticeList
                .find((ap) => ap.name === conservationPractice.name)
                .climateRegions.find((cr) => cr.id === location.climate.id)
                .farmTypes.find((ft) => ft.id === farmTypeId || ft.id === 1502)
                .agroforestryRegions.find(
                    (ar) => ar.id === location.agroforestryRegionId || ar.id === 600
                );

            biomassC = biomassCpractice.totalBiomass;

            baselineLandUseClass = soilCpractice.baselineLandUseClass;
            baselineInputClass = tempFarmType?.baselineInputClass;
            baselineManagementClass = tempFarmType?.baselineManagementClass;
            scenarioLandUseClass = soilCpractice.scenarioLandUseClass;
            scenarioInputClass = tempFarmType?.scenarioInputClass;
            scenarioManagementClass = tempFarmType?.scenarioManagementClass;
        } else {
            // Get soil carbon value
            if (soilCpracticeList.some((cp) => cp.id === conservationPractice.id)) {
                const payload = {
                    climate: location.climate,
                    soil: location.soil,
                    conservationPractice: {
                        id: conservationPractice.id,
                        name: conservationPractice.name,
                        baselineLandUseClass: conservationPractice.baselineLandUseClass,
                        baselineInputClass: farmType.baselineInputClass,
                        baselineManagementClass: farmType.baselineManagementClass,
                        scenarioLandUseClass: conservationPractice.scenarioLandUseClass,
                        scenarioInputClass: farmType.scenarioInputClass,
                        scenarioManagementClass: farmType.scenarioManagementClass,
                    },
                };

                try {
                    const result = yield call(post, RESULTS, payload);
                    soilC = result;
                } catch ({ status, message }) {
                    soilC = message.message;
                }

                baselineLandUseClass = conservationPractice.baselineLandUseClass;
                baselineInputClass = farmType?.baselineInputClass;
                baselineManagementClass = farmType?.baselineManagementClass;
                scenarioLandUseClass = conservationPractice.scenarioLandUseClass;
                scenarioInputClass = farmType?.scenarioInputClass;
                scenarioManagementClass = farmType?.scenarioManagementClass;
            }

            // Get biomass carbon value
            if (biomassCpracticeList.some((ap) => ap.id === conservationPractice.id)) {
                // Find practice with matching climate and region
                const biomassCpractice = biomassCpracticeList
                    .find((ap) => ap.id === conservationPractice.id)
                    .climateRegions.find((cr) => cr.id === location.climate.id)
                    .farmTypes.find((ft) => ft.id === farmTypeId || ft.id === 1502)
                    .agroforestryRegions.find(
                        (ar) => ar.id === location.agroforestryRegionId || ar.id === 600
                    );

                biomassC = biomassCpractice.totalBiomass;

                baselineLandUseClass = biomassCpractice?.baselineLandUseClass;
                baselineInputClass = biomassCpractice?.baselineInputClass;
                baselineManagementClass = biomassCpractice?.baselineManagementClass;
                scenarioLandUseClass = biomassCpractice?.scenarioLandUseClass;
                scenarioInputClass = biomassCpractice?.scenarioInputClass;
                scenarioManagementClass = biomassCpractice?.scenarioManagementClass;
            }
        }
    }

    yield put(
        receiveResult({
            id: conservationPractice.id,
            name: conservationPractice.name,
            area: 0,
            error: error,
            soilC: soilC,
            biomassC: biomassC,
            baselineLandUseClass: baselineLandUseClass,
            baselineInputClass: baselineInputClass,
            baselineManagementClass: baselineManagementClass,
            scenarioLandUseClass: scenarioLandUseClass,
            scenarioInputClass: scenarioInputClass,
            scenarioManagementClass: scenarioManagementClass,
        })
    );
}
