import { call, put, select } from "redux-saga/effects";

import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";

import {
    climateRegionStyles,
    countryStyles,
    soilStyles,
    usaStyles,
} from "../assets/theme/mapLayers";

import { get } from "../utils/api";

import { CLIMATE_LAYER, COUNTRIES_LAYER, SOILS_LAYER, USA_LAYER } from "../constants/api";

import {
    CLIMATE_LAYER_NAME,
    COUNTRIES_LAYER_NAME,
    HIGHLIGHTED_COUNTRY_LAYER_NAME,
    MAPLAYER_DEFAULT_OPACITY,
    MAPLAYER_ZOOM_THRESHOLD,
    SOILS_LAYER_NAME,
    USA_LAYER_NAME,
} from "../constants/mapLayers";

import {
    setClimateLoading,
    receiveClimateLookups,
    receiveClimateLoadedCountry,
    receiveClimateLoadedIds,
    setSoilLoading,
    receiveSoilLookups,
    receiveSoilLoadedCountry,
    receiveSoilLoadedIds,
} from "../reducers/map";
import { getMap } from "../store/selectors";

export function* setView(action) {
    try {
        const map = yield select(getMap);
        if (map.countryId) {
            // Enable loading messages
            yield put(setSoilLoading(true));
            yield put(setClimateLoading(true));

            // Load soil layer, otherwise disable loading message
            if (!map.soilLoadedCountries.includes(map.countryId)) {
                yield call(fetchSoilsLayer);
            }
            else {
                yield put(setSoilLoading(false));
            }
            // Load climate layer, otherwise disable loading message
            if (!map.climateLoadedCountries.includes(map.countryId)) {
                yield call(fetchClimateLayer);
            }
            else {
                yield put(setClimateLoading(false));
            }
        }
    } catch (error) {
        console.warn("Error setting map view: ", error);
    }
}

export function* toggleSelectedLayer(action) {
    const toggleLayers = [CLIMATE_LAYER_NAME, SOILS_LAYER_NAME];
    const map = yield select(getMap);
    for (const layer of window.globalPlannerMap.getAllLayers()) {
        console.log({ layer });
        const name = layer.get("name");
        if (toggleLayers.includes(name)) {
            layer.setVisible(name === map.selectedLayer);
        }
        if (name === HIGHLIGHTED_COUNTRY_LAYER_NAME) {
            if (!map.selectedLayer) {
                console.log("setting visible false");
                layer.setVisible(false);
            } else {
                console.log("setting visible true");
                layer.setVisible(true);
            }
        }
    }
}

export function* fetchUSALayer() {
    try {
        const usaData = yield call(get, USA_LAYER);

        const usaLayer = buildVectorLayer({
            features: [usaData],
            extent: [
                //found via manual checking
                -14208508.749468504, 2698702.6967700236, -7266472.566624317, 6357338.74519793,
            ],
            styles: usaStyles,
            name: USA_LAYER_NAME,
            minZoom: 0,
            opacity: 1,
            visible: true,
        });

        window.globalPlannerMap.addLayer(usaLayer);
    } catch (error) {
        console.warn("Error fetching usa layer: ", error);
    }
}

export function* fetchClimateLayer() {
    try {
        var map = yield select(getMap);
        var climateData = { results: [], lookups: [] };
        var startId = -1;

        while (startId != null) {
            const response = yield call(get, CLIMATE_LAYER, {
                startId: startId,
                countryId: map.countryId,
            });

            //add the climate polygons to climateData.results and set the new startId
            //only add polygons that haven't already been loaded - i.e. don't double-load polygons that cross country boundaries
            climateData.results = climateData.results.concat(
                response.results.filter((result) => !map.climateLoadedIds.includes(result.id))
            );

            startId =
                response.results.length > 0
                    ? response.results[response.results.length - 1].id
                    : null;

            //only add new lookups to climateData.lookups
            climateData.lookups = climateData.lookups.concat(
                response.lookups.filter(
                    (lookup) =>
                        !climateData.lookups.some((existing) => existing.id === lookup.id)
                )
            );
        }

        //get the existing climate layer
        var climateLayer = window.globalPlannerMap
            ? window.globalPlannerMap
                  .getAllLayers()
                  .find((l) => l.get("name") === CLIMATE_LAYER_NAME)
            : null;

        //if the climate layer doesn't exist, build it and add it to the map
        //otherwise, add the newly fetched features to the existing layer
        if (!climateLayer) {
            climateLayer = buildVectorLayer({
                features: climateData.results,
                styles: climateRegionStyles,
                name: CLIMATE_LAYER_NAME,
            });

            window.globalPlannerMap.getLayers().insertAt(1, climateLayer);
        } else {
            climateLayer.getSource().addFeatures(buildFeatureSet(climateData.results));
        }

        yield put(receiveClimateLoadedCountry(map.countryId));
        yield put(receiveClimateLookups(climateData.lookups));
        yield put(receiveClimateLoadedIds(climateData.results.map((r) => r.id)));
        yield put(setClimateLoading(false));
    } catch (error) {
        console.warn("Error fetching climate layer: ", error);
        yield put(setClimateLoading(false));
    }
}

// todo: we might be able to consolidate the US and countries layers into just a
// single layer so we only have to fetch it once
export function* fetchCountriesLayer() {
    try {
        const countriesData = yield call(get, COUNTRIES_LAYER);

        const countriesLayer = buildVectorLayer({
            features: countriesData.results,
            styles: countryStyles,
            name: COUNTRIES_LAYER_NAME,
            visible: true,
            minZoom: 0,
            opacity: 1,
        });

        window.globalPlannerMap.addLayer(countriesLayer);
    } catch (error) {
        console.warn("Error fetching countries layer: ", error);
    }
}

export function* fetchSoilsLayer() {
    try {
        var location = yield select(getMap);
        var soilsData = { results: [], lookups: [] };
        var startId = -1;

        while (startId != null) {
            const response = yield call(get, SOILS_LAYER, {
                startId: startId,
                countryId: location.countryId,
            });

            //add the soils polygons to soilsData.results and set the new startId
            //only add polygons that haven't already been loaded - i.e. don't double-load polygons that cross country boundaries
            soilsData.results = soilsData.results.concat(
                response.results.filter(
                    (result) => !location.soilLoadedIds.includes(result.id)
                )
            );

            startId =
                response.results.length > 0
                    ? response.results[response.results.length - 1].id
                    : null;

            //only add new lookups to soilsData.lookups
            soilsData.lookups = soilsData.lookups.concat(
                response.lookups.filter(
                    (lookup) =>
                        !soilsData.lookups.some((existing) => existing.id === lookup.id)
                )
            );
        }

        //get the existing soils layer
        var soilsLayer = window.globalPlannerMap
            ? window.globalPlannerMap
                  .getAllLayers()
                  .find((l) => l.get("name") === SOILS_LAYER_NAME)
            : null;

        //if the soils layer doesn't exist, build it and add it to the map
        //otherwise, add the newly fetched features to the existing layer
        if (!soilsLayer) {
            soilsLayer = buildVectorLayer({
                features: soilsData.results,
                styles: soilStyles,
                name: SOILS_LAYER_NAME,
            });

            window.globalPlannerMap.getLayers().insertAt(1, soilsLayer);
        } else {
            soilsLayer.getSource().addFeatures(buildFeatureSet(soilsData.results));
        }

        yield put(receiveSoilLoadedCountry(location.countryId));
        yield put(receiveSoilLookups(soilsData.lookups));
        yield put(receiveSoilLoadedIds(soilsData.results.map((r) => r.id)));
        yield put(setSoilLoading(false));
    } catch (error) {
        console.warn("Error fetching soils layer: ", error);
        yield put(setSoilLoading(false));
    }
}

function buildVectorLayer(params) {
    return new VectorLayer({
        source: new VectorSource({
            features: new GeoJSON().readFeatures({
                type: "FeatureCollection",
                features: params.features.map((c) => ({
                    type: "Feature",
                    geometry: JSON.parse(c.geometry),
                    properties: {
                        id: c.id,
                        lookupId: c.lookupId,
                    },
                })),
            }),
        }),
        extent: params.extent,
        style: (feature) => params.styles[feature.get("lookupId")] ?? params.styles["default"],
        visible: params.visible ?? false,
        minZoom: params.minZoom ?? MAPLAYER_ZOOM_THRESHOLD,
        opacity: params.opacity ?? MAPLAYER_DEFAULT_OPACITY,
        properties: {
            name: params.name,
        },
    });
}

function buildFeatureSet(results) {
    return new GeoJSON().readFeatures({
        type: "FeatureCollection",
        features: results.map((c) => ({
            type: "Feature",
            geometry: JSON.parse(c.geometry),
            properties: {
                id: c.id,
                lookupId: c.lookupId,
            },
        })),
    });
}
