import PlannerGlobalPage from "../../components/PageLayout/PlannerGlobalPage";
import Welcome from "../../components/PageLayout/Welcome";
import Dashboard from "./Dashboard";

import { useState, useEffect } from "react";

import { Link } from "../../ui";
import { LinkButton } from "../../components/Form";
import { ContinentalUSMessage } from "../../components/Information";
import { Box } from "@mui/material";
import PlannerGlobalModal from "../../components/ui/Modal";
import UseAgreement from "../../components/Documentation/UseAgreement";

const HomePage = () => {
  const HomePageWelcome = {
    header: "Welcome to COMET-Planner Global",
    body: (
      <Box>
        <p>
          COMET-Planner Global provides estimates of carbon sequestration and greenhouse gas
          emission reductions for common conservation agriculture practices across the world.
          Estimates were generated for broad climate and soil categories using{" "}
          <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/vol4.html">
            UNFCCC Intergovernmental Panel on Climate Change Guidelines for National Greenhouse
            Gas Inventories.
          </Link>
        </p>
        <ContinentalUSMessage />
      </Box>
    ),
    footer: (
      <LinkButton
        label="User Guide"
        href={process.env.PUBLIC_URL + "COMET-Planner-Global-How-To.pdf"}
      />
    ),
  };

  /*   // Display Use Agreement when the home page first loads
  const [popupState, setLocalPopup] = useState({ displayPopup: false });

  const UserAgreementSeen = () => {
    setLocalPopup({ displayPopup: false });

    localStorage.setItem("CometPlannerGlobalUseAgreementSeen", "True");
  };

  useEffect(() => {
    if (localStorage.getItem("CometPlannerGlobalUseAgreementSeen") !== "True") {
      setLocalPopup({
        displayPopup: true,
        close: UserAgreementSeen,
        header: "COMET-Planner Global Use Agreement",
        body: <UseAgreement />,
      });
    }
  }, []); */

  return (
    <PlannerGlobalPage>
      <Welcome welcomeObj={HomePageWelcome} />
      <Dashboard />

      {/*       {popupState && popupState.displayPopup && (
        <PlannerGlobalModal
          open={popupState.displayPopup}
          close={popupState.close}
          header={popupState.header}
          body={popupState.body}
        />
      )} */}
    </PlannerGlobalPage>
  );
};
export default HomePage;
