import React from "react";
import { Button } from "../Form/index.jsx";
import "./LandUse.css";

import { useDispatch, useSelector } from "react-redux";
import { setBaseline } from "../../reducers/baseline";
import { getBaselineList, getBaselineSelectedId } from "../../store/selectors.js";
import { LANDUSE_IMAGES } from "../../constants";

const LandUse = () => {
  const baselineList = useSelector(getBaselineList);
  const baselineSelectedId = useSelector(getBaselineSelectedId);
  const dispatch = useDispatch();

  const updateBaseline = (selection) => {
    if (selection !== baselineSelectedId) {
      dispatch(setBaseline(selection));
    }
  };
  const landUses = baselineList.map((e) => ({ ...e, src: LANDUSE_IMAGES[e?.id] }));

  return (
    <div className="landuse-component" style={{ paddingBottom: ".5em" }}>
      {landUses.map((e, i) => {
        return (
          <React.Fragment key={i + "landusebutton_key"}>
            <Button
              {...e}
              onClick={() => updateBaseline(e?.id)}
              className={`${
                baselineSelectedId === e?.id ? "active" : ""
              } ax-flex column flex-grow`}
            >
              <img alt={e?.name} src={e?.src} className="landuse-image" />
              <span>{e?.name}</span>
            </Button>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default LandUse;
