import { useState } from "react";
import "./Header.css";
import Branding from "./Branding";

import { Image } from "../../ui";
import { useBreakPointPxOnScroll } from "../../hooks";

import { Link as RouterLink } from "react-router-dom";

import { IconButton, Menu, MenuItem, Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const breakPoints = [50, 100, 400];

// Header menu
const MenuOptions = () => {
  const { flex } = styles();

  const [anchorElement, setAnchorElement] = useState(null);
  const open = Boolean(anchorElement);
  const handleClick = (e) => {
    setAnchorElement(e.target);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  const options = [
    {
      Label: "Home",
      Link: "/home",
    },
    {
      Label: "Documentation",
      Link: "/documentation",
    },
    {
      Label: "Contact Us",
      Link: "/contactus",
    },
  ];

  return (
    <div style={flex}>
      <IconButton onClick={handleClick}>
        <MenuIcon style={{ color: "white", transform: "scale(1.8)" }} />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 216,
            width: "20ch",
          },
        }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.Label}
            onClick={handleClose}
            component={RouterLink}
            to={item.Link}
          >
            {item.Label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const Header = () => {
  const { flex, lightOnDark } = styles();
  const [currentBreakPointSection, currentPxYOffset] = useBreakPointPxOnScroll(breakPoints);

  return (
    <div>
      {/* Top banner */}
      <div
        style={{
          ...{
            width: "100%",
            top: "0",
            zIndex: 3,
          },

          ...{
            top: currentBreakPointSection > 1 ? `${breakPoints[2] - currentPxYOffset}px` : 0,
          },
          ...(currentBreakPointSection > -1 && {
            boxShadow: "0 1px 5px 2px rgb(0 0 0 / 14%)",
          }),
          ...lightOnDark,
        }}
      >
        <div className={"desktop-only"} style={{ ...flex, ...{ margin: "0 1em" } }}>
          {/* Menu */}
          <MenuOptions />
          {/* Title */}
          <Link to="/home" component={RouterLink} color="inherit" underline="none">
            <div style={flex}>
              <h1
                style={{ fontSize: "1.1rem", padding: ".25em 0", margin: 0, color: "inherit" }}
              >
                COMET-Planner Global
              </h1>
            </div>
          </Link>
          {/* Branding/Logos */}
          <div style={{ flexGrow: "1" }}>
            <div style={{ ...flex, ...{ float: "right" } }}>
              {currentBreakPointSection < 1 && (
                <Branding light={true} className="header-child" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;

export const IntroductionVideo = ({ src }) => (
  <button
    style={{
      ...styles().flex,
      ...{
        backgroundColor: "transparent",
        border: "none",
        fontWeight: "bold",
        color: "inherit",
        textTransform: "uppercase",
        cursor: "pointer",
      },
    }}
  >
    <Image src={src} />
    <span style={{ paddingRight: "5em" }}>Introduction Video</span>
  </button>
);

const styles = () => ({
  flex: { display: "flex", gap: "1em", alignItems: "center" },
  darkOnLight: {
    color: "var(--color-1)",
    backgroundColor: "var(--color-light)",
  },
  lightOnDark: {
    color: "var(--color-light)",
    backgroundColor: "var(--color-1)",
  },
  bannerMaskDark: {
    background: `linear-gradient(0deg, rgba(75 87 61 / .4) 50%, transparent 100%)`,
  },
  bannerMaskLight: {
    background: `linear-gradient(0deg, rgba(225 225 225 / .4) 50%, transparent 100%)`,
  },
});
