import React from 'react'
import chevron_down from './svgs/chevron_down.svg'
import chevron_right02 from './svgs/chevron_right02.svg'
import classic_cog from './svgs/classic-cog.svg'
import pen_square_solid from './svgs/pen-square-solid.svg'
import pencilAlone from './svgs/pencilAlone.svg'
import pencilOutOfBox from './svgs/pencilOutOfBox.svg'
import plus_solid from './svgs/plus-solid.svg'
import theX from './svgs/theX.svg'
import trashSimple from './svgs/trash-simple.svg'
import trash_alt_regular from './svgs/trash-alt-regular.svg'
import trash_alt_regular_black from './svgs/trash-alt-regular-black.svg'
import trash_solid from './svgs/trash-solid.svg'
import trash_full_bite from './svgs/trash_full_bite.svg'
import user_cog_solid from './svgs/user-cog-solid.svg'
import question_circle from './svgs/circle-question-solid.svg'
import question_solid from './svgs/question-solid.svg'
import infoIcon from './svgs/informationIcon.svg'
import youtubeIcon from './svgs/youtube_brand.svg'
import downloadImage from './svgs/icon-download-from-cloud.svg'
import download_image_grey from './svgs/icon-download-from-cloud-grey.svg'
// import Image from 'next/image'

export const ICON_LIST = {
  theX: theX,
  chevron_down: chevron_down,
  chevron_right02: chevron_right02,
  classic_cog: classic_cog,
  pen_square_solid: pen_square_solid,
  pencilAlone: pencilAlone,
  pencilOutOfBox: pencilOutOfBox,
  plus_solid: plus_solid,
  trash_alt_regular: trash_alt_regular,
  trash_alt_regular_black: trash_alt_regular_black,
  trash_simple: trashSimple,
  trash_solid: trash_solid,
  trash_full_bite: trash_full_bite,
  user_cog_solid: user_cog_solid,
  question_circle: question_circle,
  question_solid: question_solid,
  infoIcon: infoIcon,
  youtubeIcon: youtubeIcon,
  downloadImage: downloadImage,
  download_image_grey: download_image_grey,
}

export const Icon = props => {
  const { onClick, icon, alt } = props
  return (
    <img
      className={`ax-icon-image ${
        (onClick instanceof Function || typeof onClick === 'function') && 'hoverPointer'
      }`}
      src={ICON_LIST[icon] || ICON_LIST.question_circle}
      alt={alt || 'missing icon'}
    />
  )
}

export default Icon
