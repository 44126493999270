import React from "react";
import Branding from "./Branding";

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "var(--color-1)",
        margin: "2em auto 0 auto",
      }}
    >
      <Branding light={true} className="footer-child" />
    </div>
  );
};

export default Footer;
