import React from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { DownloadButton } from "./ui/HoverSlide/ToolTipSpan";
import { BASELINE_LANDUSE_TXT, FARM_TYPE, TWENTY_YR_EXPLANATION } from "../constants";

/******************
 * HELPERS
 ******************/
function spaceOL(i) {
  const space = "\u00A0";
  let returnSpaces = space;
  if (Number.isInteger(i)) {
    for (let k = 1; k < i; k++) {
      returnSpaces += space;
    }
  }
  return returnSpaces;
}

const helpers = {
  checkArray(a, lenMin) {
    return Array.isArray(a) && a.length >= (isNaN(lenMin) ? 1 : lenMin);
  },
  getNested(o, ...args) {
    return args.reduce((obj, level) => obj && obj[level], o);
  },
  array(o, ...args) {
    return this.checkArray(this.getNested(o, ...args)) ? this.getNested(o, ...args) : [];
  },
  pipeSpacedText(valueArray) {
    function cleanData(data) {
      if (data && !isNaN(data)) return data.toFixed(4);
      return data;
    }
    return this.array(valueArray)
      .map((e) => `${cleanData(e[0])} ${cleanData(e[1])}`)
      .join(", ");
  },
};

const dz = (z) => z || "-";
const tz = (z) => z || "";
const currDateYYYY_MM_DD_HHMMSS = (dl, tl, d = new Date()) =>
  `${
    d.getFullYear() +
    dz(dl) +
    `${d.getMonth() + 1}` +
    dz(dl) +
    d.getDate() +
    " " +
    d.getHours() +
    tz(tl) +
    d.getMinutes() +
    tz(tl) +
    d.getSeconds()
  }`;

/* === CLEANSE TEXT FOR ACCEPTABLE FILENAME === */
const cleanseString = (s) => s.replace(/[^a-z0-9._-]/gi, "_");
const getFileNameDate = (n) => cleanseString(`${n}_${currDateYYYY_MM_DD_HHMMSS()}`);
/* == TABLE COLORS == */
const TABLE_COLOR = [79, 96, 91];
const TABLE_COLOR_ALTERNATE = [203, 219, 203];
const buildPdf = (props) => {
  const { body, columns, header, footer, title, fileName } = props;

  const doc = new jsPDF("landscape");
  const marginTop0 = 20;
  const marginBottom0 = 5;
  const marginTopScaler = 7;
  // const totalPageCount = "{total_pages_count_string}";

  autoTable(doc, {
    // startY: 15,
    // html: '.tftable',
    // styles: { halign: 'center' },
    headStyles: { fillColor: TABLE_COLOR },
    alternateRowStyles: { fillColor: TABLE_COLOR_ALTERNATE },
    tableLineColor: TABLE_COLOR_ALTERNATE,
    tableLineWidth: 0.1,

    body: helpers.array(body),
    columns: helpers.array(columns),
    tableWidth: "auto",
    styles: { cellPadding: 0.5, fontSize: 8, minCellWidth: 20 },
    rowPageBreak: "auto",
    didDrawPage: function (data) {
      const marginLeft = data.settings.margin.left;
      doc.setFontSize(13);
      doc.setTextColor(40);
      doc.text(title, marginLeft, marginTop0 - marginTopScaler);
      doc.setFontSize(11);
      (() => {
        helpers.array(header).forEach((h, index) => {
          doc.text(h, marginLeft, marginTop0 + marginTopScaler * (index + 1));
        });
      })();
      /* prettier-ignore */
      let str = `${process.env.REACT_APP_PUBLIC_URL ? `For more information on how these estimates were generated, please visit ${process.env.REACT_APP_PUBLIC_URL}.` : ""}`
      /* prettier-ignore */
      str += `${spaceOL(33)}Version: ${process.env.REACT_APP_VERSION}${spaceOL(4)} ${new Date().toLocaleString()}`;
      doc.setFontSize(10);
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.text(str, marginLeft, pageHeight - marginBottom0);
      (() => {
        helpers.array(footer).forEach((f, index) => {
          doc.text(
            `${f}`,
            marginLeft,
            pageHeight - marginBottom0 - 2 - marginBottom0 * (footer.length - index)
          );
        });
      })();
    },
    margin: {
      top: marginTop0 + marginTopScaler * (helpers.array(header).length + 1),
      bottom: 32,
    },
  });
  /**
   * Not sure exactly how doc.putTotalPages ('string') works
   * Appears to replace the selected txt
   * i.e. {total_pages_count_string}
   *  with the total page count.
   * Running putTotalPages at end of file...assuming it needs to run after all pages
   * are 'Drawn'
   *
   * COMMENTING OUT FOR NOW PER CONVERSATION WITH BEN
   */

  /* function pageOfPagesTxt() {
        return `Page ${doc.internal.getNumberOfPages()} of ${totalPageCount}`;
    }
    doc.putTotalPages(totalPageCount); */

  doc.save(`${getFileNameDate(fileName || title)}.pdf`);
};

const PdfReportDownload = (props) => {
  const default_props = {
    // prettier-ignore
    title: `COMET-Planner Global${spaceOL(4)}|${spaceOL(4)}Approximate Carbon Sequestration and Greenhouse Gas Emission Reductions`,
    body: helpers.array(props.body),
    columns: helpers.array(props.columns),
    fileName: props.projectName || "comet-planner-global-report",
    header: [
      `Location: ${helpers.pipeSpacedText(props.location)}`,
      `Climate: ${props.climate}`,
      `Soil: ${props.soil}`,
      "",
      `${FARM_TYPE}: ${props.farmType}`,
      `${BASELINE_LANDUSE_TXT}: ${props.baseline}`,
      // prettier-ignore
      // `Version: ${process.env.REACT_APP_VERSION}${spaceOL(4)}Date and Time: ${new Date().toLocaleString()}`,
      // `Date and Time: ${new Date().toLocaleString()}${spaceOL(4)}Version: ${process.env.REACT_APP_VERSION}`,
    ],
    footer: [`${TWENTY_YR_EXPLANATION}`, ""],
  };

  return (
    <>
      {props.disabled && false ? (
        <button style={{ cursor: "not-allowed", textAlign: "center" }}>
          Fix Errors to enable Download
        </button>
      ) : (
        <DownloadButton
          onClick={() => {
            props.onClick();
            buildPdf(Object.assign({ ...default_props, ...props }));
          }}
          label={props.label}
          disabled={props.disabled}
        />
      )}
    </>
  );
};

export default PdfReportDownload;
