const BACKEND = process.env.REACT_APP_BACKEND_URL;
// const BACKEND = process.env.REACT_APP_CORS_TESTING_PREFIX;

export const LIST_BASELINE = `${BACKEND}/home/listBaseline`;

export const LIST_CONSERVATION_PRACTICES = `${BACKEND}/home/listConservationPractices`;

export const LIST_AGROFORESTRY_PRACTICES = `${BACKEND}/home/listAgroforestryPractices`;

export const LOCATION_DATA = `${BACKEND}/home/locationData`;

export const RESULTS = `${BACKEND}/home/results`;

export const CLIMATE_LAYER = `${BACKEND}/home/climateLayer`;

export const SOILS_LAYER = `${BACKEND}/home/soilsLayer`;

export const COUNTRIES_LAYER = `${BACKEND}/home/countriesLayer`;

export const USA_LAYER = `${BACKEND}/home/usaLayer`;

export const CONTACT_US = `${BACKEND}/service/contactus`;
