import { ChangeList } from "./Changes";

import { Box, Grid, Typography } from "@mui/material";

const Changelog = () => {
  // Reverse the order of the change list so the oldest version is at the bottom
  const orderedLog = ChangeList.slice().reverse();

  return (
    <Box sx={{ backgroundColor: "#8898aa20" }}>
      <Grid px={2} direction="column" container justifyContent="start">
        {orderedLog.map((change, i) => {
          return (
            <Box key={change.Version}>
              {/* Version header */}
              <Typography
                variant="h2"
                fontSize="1.3em"
                color="var(--color-1)"
                pt={i !== 0 ? "1em" : 0}
              >
                {change.Version}
              </Typography>
              {/* Approx date released */}
              <Typography variant="h3" fontWeight="bold">
                {change.Date}
              </Typography>
              {/* Major changes text */}
              <Typography variant="h4">{change.MajorChange}</Typography>
              {/* Bullet list of specific changes */}
              {change.MinorChanges.map((minorChange, i) => {
                return (
                  <Typography
                    key={i}
                    variant="h4"
                    sx={{ paddingLeft: "1em" }}
                  >{`- ${minorChange}`}</Typography>
                );
              })}
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};
export default Changelog;
