import React from "react";
import { Link } from "../../ui";
import { FARM_TYPE } from "../../constants";

import ClimateZonesMap from "./assets/climateZonesMap.png";
import SoilZonesMap from "./assets/soilZonesMap.png";

const styles = { margin: "auto" };

export const ContinentalUSMessage = () => {
  return (
    <>
      For sites within the <em> contiguous United States</em>, please use{" "}
      <Link href="http://comet-planner.com">comet-planner.com</Link> to obtain more accurate
      results.
    </>
  );
};

export const FarmLocationInfo = () => {
  return (
    <p className="information-container" style={styles}>
      Farm or ranch location is used to determine the climate zone and soil classification for
      carbon sequestration calculations. Climate and soil classifications are described in IPCC
      Guidelines
      <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch03_Land%20Representation.pdf">
        Chapter 3
      </Link>
      in Annex 3A.5.
    </p>
  );
};
export const FarmTypeInfo = () => {
  return (
    <div className="information-container" style={styles}>
      {`${FARM_TYPE}`} is used to determine baseline plant residue inputs.{" "}
      <p>
        <HighVisText text={"Commercial scale farms and plantations"} /> are assumed to have
        medium baseline residue input levels due to use of fertilizers and other agricultural
        inputs.
      </p>
      <p>
        <HighVisText text={"Smallholder farms"} /> are assumed to have low residue baseline
        input levels due to more limited use of fertilizers and frequent use of crop residues
        as fodder.
      </p>
    </div>
  );
};
export const BaselineLandUseInfo = () => {
  return (
    <div className="information-container" style={styles}>
      Current land use is used to determine baseline carbon stocks.
      <p>
        <b>Cultivated Annual Croplands </b>
        are arable and tillable lands used to grow annual crops such as cereals, oils seeds,
        vegetables, root crops and forages.
      </p>
      <p>
        <b>Pasture and Rangelands </b>
        are not tilled, typically contain perennial grasses, and are often used for livestock
        grazing.
      </p>
    </div>
  );
};

export const ConservationPractInfo = () => {
  return (
    <p className="information-container" style={styles}>
      A
      <Link href="https://www.fao.org/conservation-agriculture/overview/what-is-conservation-agriculture/en/">
        conservation agriculture practice
      </Link>
      is an agricultural management practice that promotes maintenance of a permanent soil
      cover, minimum soil disturbance, and diversification of plant species.
    </p>
  );
};

export const ResultsCardInfo = () => (
  <p className="information-container" style={styles}>
    Enter the area in hectares to which the conservation agriculture practice should apply.
    This might be to a single field, portion of a field, or a group of fields if in close
    proximity. Climate and soil descriptions reflect the location of the point selected on the
    map. Climate and soil classifications are described in IPCC Guidelines
    <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch03_Land%20Representation.pdf">
      Chapter 3
    </Link>
    in Annex 3A.5.
  </p>
);

export const Methodology = () => {
  return (
    <div
      style={{
        className: "methodology-explanation",
        maxWidth: "var(--max-width-prose)",
        margin: "auto",
        backgroundColor: "#8898aa20",
        padding: ".75em",
        borderRadius: "5px",
      }}
    >
      <h2>Overview</h2>
      <p>
        The COMET-Planner Global tool currently provides estimates of carbon dioxide (CO
        <sub>2</sub>) removals, associated with management-induced carbon stock increases in
        soils and/or biomass, from implementation of common agricultural and land use
        conservation practices. Future versions of COMET-Planner Global may include reductions
        in greenhouse gas emissions for nitrous oxide (N<sub>2</sub>O) and methane (CH
        <sub>4</sub>). COMET-Planner Global covers all parts of the world where soil and
        climate data are available. The contiguous United States is covered by the domestic
        COMET-Planner tool. Users assessing practices in the contiguous United States should
        utilize COMET-Planner and access the tool at
        <Link href="http://www.comet-planner.com/">www.comet-planner.com.</Link>
      </p>
      <h2>Methodology for estimating agricultural carbon stock changes</h2>
      <p>
        Soil and perennial biomass carbon dioxide removals delivered through the adoption of
        Climate-Smart conservation practices were estimated using
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/vol4.html">
          UNFCCC Intergovernmental Panel on Climate Change (IPCC) Guidelines for National
          Greenhouse Gas Inventories
        </Link>
        (IPCC 2019). This methodology was designed for national inventories and not site level
        quantification. However, the methodology was leveraged for COMET-Planner Global as a
        general estimation approach (<span style={{ fontStyle: "italic" }}>ex-ante</span>) for
        climate-smart conservation planning purposes and to help users understand how
        conservation practices may impact carbon dioxide removals within broad climate and soil
        categories. The methods in the COMET-Planner Global tool employ the Tier 1 methods, as
        described in the
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch01_Introduction.pdf">
          Introduction of Volume 4: Agriculture, Forestry and Other Land Use.
        </Link>
        The IPCC guidance provides the equations and emission factors for all regions that are
        required to conduct a Tier 1 analysis.
      </p>
      <p>
        In the initial version of the COMET-Planner Global, only emissions for carbon dioxide
        removals in mineral soils and woody biomass carbon were evaluated. Future versions of
        the tool will include additional greenhouse gas emission source categories related to
        agriculture and land use change. The Tier 1 method for soil carbon stock changes can be
        found in
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch02_Generic Methods.pdf">
          Chapter 2, Generic Methodologies Applicable to Multiple Land-Use Categories.
        </Link>
        In general terms, this is an empirical method that provides a reference soil carbon
        stock for each climate and soil zone. The methodology adjusts the carbon stock
        according to land use, carbon inputs, soil disturbance, and other management factors to
        deliver an <span style={{ fontStyle: "italic" }}>ex-ante</span> estimate of net CO
        <sub>2</sub> removal into soil organic matter and woody biomass achieved through
        conservation practice implementation. Additional details related to specific land use
        categories for this tool can be found in
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch05_Cropland.pdf">
          Chapter 5, Cropland
        </Link>
        and
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch06_Grassland.pdf">
          Chapter 6, Grassland
        </Link>
        of the guidelines. Additional land use categories may be added in future versions of
        the COMET-Planner Global tool.
      </p>
      <p>
        For agroforestry woody biomass carbon stock changes, the default Tier 1 method utilizes
        biomass accumulation from growth estimates, minus any losses due to harvesting and
        other removals. This tool utilizes estimates for woody biomass carbon accumulation by
        climate zones from
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch05_Cropland.pdf#page=8&zoom=auto,-274,3">
          Chapter 5, Table 5.2.
        </Link>
      </p>
      <p>
        Reference soil carbon stocks and woody biomass carbon accumulation rates (by climate
        zone only) for the IPCC Tier 1 method are provided by broad soil and climate zones,
        which are defined in{" "}
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch03_Land Representation.pdf">
          Chapter 3, Consistent Representation of Lands.
        </Link>{" "}
        The climate dataset was developed at Colorado State University, following the guidance
        provided in{" "}
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch03_Land Representation.pdf#page=45&zoom=auto,37,469">
          Chapter 3, Annex 3A.5
        </Link>{" "}
        (Figure 1). The soil dataset was developed at ISRIC World Soil Information (Batjes
        2009) (Figure 2) for the{" "}
        <Link href="https://www.carbonbenefitsproject.org/">Carbon Benefits Project.</Link>{" "}
        These layers can be viewed by users in the COMET-Planner Global map interface and are
        queried when a user selects a map location for use in the calculations.
      </p>
      <img
        src={ClimateZonesMap}
        alt="Figure 1. IPCC climate zones required for Tier 1 soil carbon stock analyses."
        style={{ width: "100%" }}
      />
      <span style={{ display: "block", textAlign: "initial" }}>
        Figure 1. IPCC climate zones required for Tier 1 soil carbon stock analyses.
      </span>
      <br />
      <img
        src={SoilZonesMap}
        alt="Figure 2. IPCC soil zones required for Tier 1 soil carbon stock analyses."
        style={{ width: "100%" }}
      />
      <span style={{ display: "block", textAlign: "initial" }}>
        Figure 2. IPCC soil zones required for Tier 1 soil carbon stock analyses.
      </span>
      <br />
      <h2>Climate Smart Conservation Practices</h2>
      <p>
        Conservation practices included in COMET-Planner Global are those that are common and
        have been shown to increase soil carbon stocks, according to the IPCC Tier 1 method.
        The COMET-Planner Global tool only includes climate-beneficial conservation practices,
        this is also a condition of the COMET-Planner tool. Accordingly, not all conservation
        practices increase soil carbon in all climate zones, therefore practices were only
        included in climate zones where they have a climate benefit relative to conventional
        practices. Short descriptions of Cultivated Annual Cropland and Pastures and Rangeland
        with their associated conservation practices and the assumptions captured in the
        estimates are described below.
      </p>
      <p>
        <em>Cultivated Annual Cropland:</em> The baseline cultivated annual cropland scenarios
        assume a system without any existing climate-smart conservation practices. The crop
        types are generally categorized as being low C input systems (e.g., low residue
        producing crops, crop rotations with bare fallows, or systems where residues are
        removed from fields) or medium input systems (medium residue producing crops or systems
        where residues are retained in fields). For the purposes of this tool, it is assumed
        that in tropical regions, "Smallholder" farm types have a low C input baseline and
        "Commercial/Plantation" farm types have a medium input baseline. In temperate climates,
        dry climate zones are assumed to have low inputs, and wet climate zones are assumed to
        have medium inputs, regardless of farm type. All baselines are assumed to be under full
        tillage and with no additional carbon inputs. Please review{" "}
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch05_Cropland.pdf#page=27&zoom=auto,-274,847">
          IPCC Guidance Chapter 5, Table 5.5
        </Link>{" "}
        for carbon stock change factors for management of croplands.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>Add seasonal cover crops</span> - This practice
        assumes that a seasonal cover crop is planted during a time of the year when the field
        would typically be bare fallow, increasing overall plant residue inputs to the system.
        This practice would increase the carbon inputs from low to medium, or medium to high
        without manure, depending on the climate zone and farm type selected. No other
        management practices change.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>Apply compost or manure</span> - This practice
        assumes the addition of compost or manures to the system. This practice would increase
        the carbon inputs from low to medium, or medium to high with manure, depending on the
        climate zone and farm type selected. No other management practices change.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>
          Convert annual cropland to set-aside (revegetate with perennial grasses)
        </span>{" "}
        - This practice assumes that cultivated annual cropland is converted to perennial
        herbaceous vegetation. This is represented as a land use change from long-term
        cultivated to set-aside.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>Convert from full tillage to no-till</span> -
        This practice assumes a conversion from full tillage to no-till. No other management
        practices change.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>
          Reduce bare-fallowing or use perennial grasses in crop rotations
        </span>{" "}
        - This practice assumes a reduction of frequency of bare-fallowing in a crop rotation
        or rotating perennial grasses into annual crop rotations, resulting in an increased
        production of plant residues in the system. This practice would increase the carbon
        inputs from low to medium, or medium to high without manure, depending on the climate
        zone and farm type selected. No other management practices change.
      </p>
      <p>
        <em>Pastures and Rangelands:</em> Pastures and Rangelands are assumed to be permanent
        grasslands (> 20 years). The baseline assumptions for each conservation practice vary
        slightly and are described with each practice. Please review the{" "}
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch06_Grassland.pdf#page=6&zoom=auto,-13,817">
          IPCC Guidance Chapter 6, Table 6.2
        </Link>{" "}
        for stock change factors for management of grasslands.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>Add compost or manure</span> - This practice
        assumes a baseline of improved grassland with medium C inputs. Application of compost
        or manure changes the input level to improved grassland with high inputs. The practice
        assumptions are the same across all climate zones.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>Reduce grazing intensity</span> - This practice
        assumes that the baseline is under high intensity grazing. When grazing intensity is
        reduced to low or medium intensity under this practice, the grassland shifts to
        nominally managed and a non-degraded condition.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>Restore severely degraded grasslands</span> - The
        practice assumes that the baseline system is severely degraded due to severe mechanical
        damage to the vegetation and/or severe soil erosion. After grasslands are restored, the
        grassland shifts to nominally managed and a non-degraded condition.
      </p>
      <p>
        <em>Agroforestry:</em> Agroforestry systems are assumed to be new plantings, converted
        from annual croplands or pastures and rangelands. The description of each practice is
        provided below. Biomass carbon estimates for each system were derived from{" "}
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch05_Cropland.pdf#page=9&zoom=auto,-158,839">
          IPCC Guidance Chapter 5, Table 5.2
        </Link>
        . Not all practices are available in all regions and some regions have no practices, as
        can be seen in Table 5.2. Further, some agroforestry systems described in the IPCC
        methodologies are relevant for national greenhouse gas inventories, but not applicable
        in the context of conservation planning. Any practices that would increase emissions
        relative to the baseline were excluded. For practices indicated below that are
        estimated to have a soil carbon benefit, please review{" "}
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch05_Cropland.pdf#page=27&zoom=auto,-274,847">
          IPCC Guidance Chapter 5, Table 5.5
        </Link>{" "}
        for carbon stock change factors for the management of croplands. Soil carbon estimates
        only apply to lands that were previously cultivated annual cropland, and which will no
        longer be cultivated under the agroforestry system.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>
          Convert strip of annual cropland to a hedgerow or windbreak
        </span>
        – This practice assumes a strip of cultivated annual cropland is converted to linear
        woody plantings at the edge of the field, such as hedgerows, shelterbelts, windbreaks,
        boundary plantings, and live fences. For the purposes of this tool, it is assumed the
        linear plantings are 5 meters in width. Soil carbon is estimated assuming a land use
        change from long-term cultivated to set-aside.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>
          Convert strip of pasture or rangeland to a hedgerow or windbreak
        </span>
        - This practice assumes a strip of pasture or rangeland is converted to linear woody
        plantings at the edge of the paddock, such as hedgerows, shelterbelts, windbreaks,
        boundary plantings, and live fences. For the purposes of this tool, it is assumed the
        linear plantings are 5 m in width. No soil carbon change is estimated, since the
        baseline land use is permanent grassland.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>
          Convert annual cropland to silvoarable system
        </span>
        – This practice assumes the planting of woody species in parallel tree rows to allow
        cultivation of annual crops between rows. No soil carbon change is estimated because
        much of the field area remains under annual crop cultivation.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>
          Convert pasture or rangeland to silvopastoral system
        </span>
        – This practice assumes the planting of woody species on permanent grasslands, which
        are often grazed. No soil carbon change is estimated since the baseline land use is
        permanent grassland.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}> Convert annual cropland to alley cropping</span>
        – This practice assumes the planting of woody species, often fast growing and at high
        densities, in crop fields. No soil carbon change is estimated because much of the field
        area remains under annual crop cultivation.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>
          Convert annual cropland to fallow with trees
        </span>
        – This practice assumes land is rested from cultivation, with planted and managed
        trees, shrubs and herbaceous cover crops and can be natural or improved. Soil carbon is
        estimated assuming a land use change from long-term cultivated to set-aside.
      </p>
      <p>
        <span style={{ fontStyle: "italic" }}>
          Convert annual cropland to multistrata system
        </span>
        – This practice assumes the planting of a multistorey system of a variety of trees, and
        perennial and annual crops. This system may be home gardens or agroforests. Soil carbon
        is estimated assuming a land use change from long-term cultivated to set-aside,
        assuming most of the system comprised of perennial trees and crops.
      </p>
      <h2>References</h2>
      <p>
        Batjes NH 2009. IPCC default soil classes derived from the Harmonized World Soil Data
        Base (Ver. 1.0). Report 2009/02, Carbon Benefits Project (CBP) and ISRIC – World Soil
        Information, Wageningen (with dataset). (
        <Link href="https://www.isric.org/sites/default/files/isric_report_2009_02.pdf">
          https://www.isric.org/sites/default/files/isric_report_2009_02.pdf
        </Link>
        )
      </p>
      <p>
        IPCC 2019. 2019 Refinement to the 2006 IPCC Guidelines for National Greenhouse Gas
        Inventories, Volume 4, Agriculture, Forestry and Other Land Use. Calvo Buendia, E.,
        Tanabe, K., Kranjc, A., Baasansuren, J., Fukuda, M., Ngarize S., Osako, A., Pyrozhenko,
        Y., Shermanau, P. and Federici, S. (eds). Published: IPCC, Switzerland. URL:{" "}
        <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/vol4.html">
          https://www.ipcc-nggip.iges.or.jp/public/2019rf/vol4.html
        </Link>
      </p>
    </div>
  );
};

const HighVisText = ({ text }) => <b style={{ fontSize: "1.1em" }}> {text} </b>;
