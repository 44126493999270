import { AREA, DATA_BASE_NO_SOILS_DATA } from "../constants";

export const calcSoilCarbonPerYear = (e) => {
    if (e.soilC === undefined || e.soilC === null) return "Error";
    if (e.soilC === DATA_BASE_NO_SOILS_DATA) return e.soilC + "**";

    /**
     * NOTE:
     * Return is dividing by 20 years b/c the data passed by
     * controllers is returning 20 year result.
     */
    return cleanData((e.soilC * e.area) / 20 || 0);
};

export const calcBiomassCarbonPerYear = (e) => {
    if (e.biomassC === undefined || e.biomassC === null) return "Error";

    /**
     * NOTE:
     * Return is multiplied by -1 b/c the value retrieved from
     * the practice list is stored as an emission reduction
     */
    return cleanData(e.biomassC * -1 * e.area || 0);
};

/**
 * @calcCO2ePerYear assumes it is being passed an object containing this 
 * structure:
 *{
        id: int,
        area: float,
        error: null or string,
        soilC: float,
        biomassC: float,
    }
 */
export const calcCO2ePerYear = (e) => {
    // Area checks
    if (e.area > AREA.MAX || e.area < AREA.MIN)
        return (
            <>
                Field Size must be between
                <b> {AREA.MIN} </b>
                and
                <b> {AREA.MAX} </b>
                hectares
            </>
        );
    if (isNaN(e.area) || e.area === 0) return <span>--</span>;

    // Error check
    if (typeof e.error === "string") return e.error;

    const biomassC = calcBiomassCarbonPerYear(e);
    const soilC = calcSoilCarbonPerYear(e);

    if (typeof biomassC === "number" && typeof soilC === "number") return biomassC + soilC;
    else return "Error";
};

export const calcCO2eTwentyYear = (e) => calcCO2ePerYear(e) * 20 || calcCO2ePerYear(e);

export function cleanData(e) {
    const mathRound = Math.round(e * 100) / 100;
    return mathRound;
    //return parseFloat(mathRound.toPrecision(2)); // Removing per Amy 4/19/24, sticking with two-decimal point accuracy
}

export function getLandUseIdFromBaselineId(baselineId) {
    switch (baselineId) {
        case 1: // Cultivated Annual Croplands
            return 9708; // Long Term Cultivation
        case 2: // Pastures and Rangelands
            return 9702; // Permanent Grassland
        case 6: // Perennial Tree Crops
            return 9703; // Perennial Cropland
        case 7: // Agroforestry
            return "314159265_agroforestry_landuseID_placeholder"; // Placeholder
        default:
            return null;
    }
}

export function displayPractice(item, location, landUseClassId) {
    return (
        location?.climate && // Make sure location has climate info
        item.baselineLandUseClass.id === landUseClassId && // Make sure practice baseline land use class matches selection
        item.climateRegions.some((cr) => cr.id === location.climate.id || cr.id === null) // Make sure either practice climate regions includes the climate of the selected location, or has an "Any" category
    );
}

// For a given practice, check to see if should be displayed based on selected scenario info
export function displayAgroforestryPractice(
    practice,
    location,
    baselineLandUseID,
    farmTypeId
) {
    return (
        location?.climate && // Make sure location has climate info
        practice.baselineLandUse === baselineLandUseID && // Make sure baseline land use for the practice matches selection
        practice.climateRegions.some((cr) => cr.id === location.climate.id) && // As of 4/16/24, all agroforestry practices have climates, so shouldn't need a conditional. This may change in the future
        practice.climateRegions.some((cr) =>
            checkFarmTypesInClimate(
                cr,
                location.agroforestryRegionId,
                location.climate.id,
                farmTypeId
            )
        ) // Make sure the matching climate region has a valid region as well
    );
}

// Check if a given climate region object contains an agroforestry geographical region, or an All Regions entry
function checkFarmTypesInClimate(climateRegion, regionId, climateId, farmTypeId) {
    return (
        climateRegion.id === climateId && // Make sure the climates match
        climateRegion.farmTypes.some((ft) => ft.id === farmTypeId || ft.id === 1502) && // Make sure farm types match, or there's an "Any" entry
        climateRegion.farmTypes.some((ft) => checkGeoRegionsInFarmType(ft, regionId)) // Make sure
    );
}

// TODO this is too many steps
function checkGeoRegionsInFarmType(farmType, regionId) {
    return (
        farmType.agroforestryRegions.some((ar) => ar.id === regionId) || // Make sure there's a region with a matching id, OR
        (!farmType.agroforestryRegions.some((ar) => ar.id === regionId) &&
            farmType.agroforestryRegions.some((ar) => ar.id === 600)) // Make sure there are no regions with a matching id, and there's an "All Regions" entry
    );
}

function isNumeric(value) {
    if (value === undefined || value === null || value === "") return false;
    if (!isNaN(value)) return true;
    if (typeof value !== "string") return false; // we only process strings from here
    return (
        !isNaN(value) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(value))
    ); // ...and ensure strings of whitespace fail
}
const formatNumber = new Intl.NumberFormat("en-US");
export const num2numWithCommas = (str) => (isNumeric(str) ? formatNumber.format(str) : str);

export const numberSignClassname = (val) => {
    const cn = "number" + " ";
    if (!isNumeric(val)) return "";
    if (+val === 0) return cn + "zero";
    if (val < 0) return cn + "negative";
    if (val > 0) return cn + "positive";
};
