import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    centerLat: null,
    centerLon: null,
    zoom: null,
    selectedLayer: null,
    countryId: null,
    climateLookups: null,
    climateLoading: null,
    climateLoadedCountries: [],
    climateLoadedIds: [],
    soilLookups: null,
    soilLoading: null,
    soilLoadedCountries: [],
    soilLoadedIds: [],
};

export const mapSlice = createSlice({
    name: "map",
    initialState,
    reducers: {
        setView: (state, action) => {
            state.centerLat = action.payload.centerLat;
            state.centerLon = action.payload.centerLon;
            state.zoom = action.payload.zoom;
            state.countryId = action.payload.countryId;
        },
        toggleSelectedLayer: (state, action) => {
            if (state.selectedLayer === action.payload) {
                state.selectedLayer = null;
            } else {
                state.selectedLayer = action.payload;
            }
        },
        setClimateLoading: (state, action) => {
            state.climateLoading = action.payload;
        },
        receiveClimateLookups: (state, action) => {
            //create the list if it doesn't exist
            state.climateLookups = state.climateLookups ?? [];

            //only add the lookups that aren't already in the list
            state.climateLookups = state.climateLookups
                .concat(
                    action.payload.filter(
                        (lookup) => !state.climateLookups.some((s) => s.id === lookup.id)
                    )
                )
                .sort();
        },
        receiveClimateLoadedCountry: (state, action) => {
            state.climateLoadedCountries.push(action.payload);
            state.climateLoadedCountries.sort();
        },
        receiveClimateLoadedIds: (state, action) => {
            state.climateLoadedIds = state.climateLoadedIds.concat(action.payload);
        },
        setSoilLoading: (state, action) => {
            state.soilLoading = action.payload;
        },
        receiveSoilLookups: (state, action) => {
            //create the list if it doesn't exist
            state.soilLookups = state.soilLookups ?? [];

            //only add the lookups that aren't already in the list
            state.soilLookups = state.soilLookups
                .concat(
                    action.payload.filter(
                        (lookup) => !state.soilLookups.some((s) => s.id === lookup.id)
                    )
                )
                .sort();
        },
        receiveSoilLoadedCountry: (state, action) => {
            state.soilLoadedCountries.push(action.payload);
            state.soilLoadedCountries.sort();
        },
        receiveSoilLoadedIds: (state, action) => {
            state.soilLoadedIds = state.soilLoadedIds.concat(action.payload);
        },
    },
});

export const {
    setView,
    toggleSelectedLayer,
    setClimateLoading,
    setSoilLoading,
    receiveClimateLookups,
    receiveSoilLookups,
    receiveClimateLoadedCountry,
    receiveSoilLoadedCountry,
    receiveClimateLoadedIds,
    receiveSoilLoadedIds,
} = mapSlice.actions;

export const mapReducer = mapSlice.reducer;
