import { Fill, Stroke, Style } from "ol/style";

//we only have climate data for lookups 2009 through 2014
//prettier-ignore
export const climateRegionColors = {
    2000: "dimgray",            // Boreal Dry
    2001: "navy",               // Boreal Moist
    2002: "fuchsia",            // Cool Temperate Dry
    2003: "palegoldenrod",      // Cool Temperate Moist
    2004: "yellow",             // Tropical Dry
    // 2005: "",                   // Tropical Moist Long Dry Season
    // 2006: "",                   // Tropical Moist Short Dry Season
    // 2007: "",                   // Tropical Montane Dry
    // 2008: "",                   // Tropical Montane Moist
    2009: "darkgreen",          // Tropical Wet
    2010: "orange",             // Warm Temperate Dry
    2011: "blue",               // Warm Temperate Moist
    2012: "lightpink",          // Tropical Montane
    2013: "limegreen",          // Tropical Moist
    2014: "aqua",               // Polar Moist
    2015: "white",              // Polar Dry
};

export const climateRegionStyles = {
    2000: new Style({
        stroke: new Stroke({ color: climateRegionColors[2000], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2000] }),
    }),
    2001: new Style({
        stroke: new Stroke({ color: climateRegionColors[2001], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2001] }),
    }),
    2002: new Style({
        stroke: new Stroke({ color: climateRegionColors[2002], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2002] }),
    }),
    2003: new Style({
        stroke: new Stroke({ color: climateRegionColors[2003], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2003] }),
    }),
    2004: new Style({
        stroke: new Stroke({ color: climateRegionColors[2004], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2004] }),
    }),
    2005: new Style({
        stroke: new Stroke({ color: climateRegionColors[2005], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2005] }),
    }),
    2006: new Style({
        stroke: new Stroke({ color: climateRegionColors[2006], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2006] }),
    }),
    2007: new Style({
        stroke: new Stroke({ color: climateRegionColors[2007], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2007] }),
    }),
    2008: new Style({
        stroke: new Stroke({ color: climateRegionColors[2008], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2008] }),
    }),
    2009: new Style({
        stroke: new Stroke({ color: climateRegionColors[2009], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2009] }),
    }),
    2010: new Style({
        stroke: new Stroke({ color: climateRegionColors[2010], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2010] }),
    }),
    2011: new Style({
        stroke: new Stroke({ color: climateRegionColors[2011], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2011] }),
    }),
    2012: new Style({
        stroke: new Stroke({ color: climateRegionColors[2012], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2012] }),
    }),
    2013: new Style({
        stroke: new Stroke({ color: climateRegionColors[2013], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2013] }),
    }),
    2014: new Style({
        stroke: new Stroke({ color: climateRegionColors[2014], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2014] }),
    }),
    2015: new Style({
        stroke: new Stroke({ color: climateRegionColors[2015], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2015] }),
    }),
    2016: new Style({
        stroke: new Stroke({ color: climateRegionColors[2016], width: 1 }),
        fill: new Fill({ color: climateRegionColors[2016] }),
    }),
};

export const soilColors = {
    10101: "green",
    10102: "purple",
    10103: "yellow",
    10104: "orange",
    10105: "lightpink",
    10106: "red",
    10107: "yellowgreen",
    10108: "aqua",
    10109: "rgba(0, 0, 0, 1)",
    10110: "darkgray",
    10111: "rgba(255, 255, 255, 1)",
    10112: "blue",
};

export const soilStyles = {
    10101: new Style({
        stroke: new Stroke({ color: soilColors[10101], width: 1 }),
        fill: new Fill({ color: soilColors[10101] }),
    }),
    10102: new Style({
        stroke: new Stroke({ color: soilColors[10102], width: 1 }),
        fill: new Fill({ color: soilColors[10102] }),
    }),
    10103: new Style({
        stroke: new Stroke({ color: soilColors[10103], width: 1 }),
        fill: new Fill({ color: soilColors[10103] }),
    }),
    10104: new Style({
        stroke: new Stroke({ color: soilColors[10104], width: 1 }),
        fill: new Fill({ color: soilColors[10104] }),
    }),
    10105: new Style({
        stroke: new Stroke({ color: soilColors[10105], width: 1 }),
        fill: new Fill({ color: soilColors[10105] }),
    }),
    10106: new Style({
        stroke: new Stroke({ color: soilColors[10106], width: 1 }),
        fill: new Fill({ color: soilColors[10106] }),
    }),
    10107: new Style({
        stroke: new Stroke({ color: soilColors[10107], width: 1 }),
        fill: new Fill({ color: soilColors[10107] }),
    }),
    10108: new Style({
        stroke: new Stroke({ color: soilColors[10108], width: 1 }),
        fill: new Fill({ color: soilColors[10108] }),
    }),
    10109: new Style({
        stroke: new Stroke({ color: soilColors[10109], width: 1 }),
        fill: new Fill({ color: soilColors[10109] }),
    }),
    10110: new Style({
        stroke: new Stroke({ color: soilColors[10110], width: 1 }),
        fill: new Fill({ color: soilColors[10110] }),
    }),
    10111: new Style({
        stroke: new Stroke({ color: soilColors[10111], width: 1 }),
        fill: new Fill({ color: soilColors[10111] }),
    }),
    10112: new Style({
        stroke: new Stroke({ color: soilColors[10112], width: 1 }),
        fill: new Fill({ color: soilColors[10112] }),
    }),
};

export const usaStyles = {
    default: new Style({
        fill: new Fill({ color: "rgba(0, 0, 0, 0.65)" }),
    }),
};

export const countryStyles = {
    default: new Style({
        fill: new Fill({ color: "rgba(0, 0, 0, 0)" }),
    }),
};
