import React from 'react'

/**
 * @getValue
 * get nested value of key named 'value' or direct value
 * ie: x = 'get this value'  or  x = {value:'get this value'}
 */

function checkIfReactElement(x) {
  return x instanceof Function || typeof x === 'function'
    ? x()
    : isObject(x)
    ? React.isValidElement(x)
      ? x
      : x?.value || ''
    : x || ''
}

export const getValue = x => {
  const y = checkIfReactElement(x)
  return isNumeric(y) ? +y.toFixed(3) : y
}

function isObject(o) {
  return typeof o === 'object' && o !== null && !Array.isArray(o) && Object.keys(o).length >= 1
}

export function getObject(o) {
  if (!isObject(o)) return {}
  return o
}

function isNumeric(value) {
  if (value === undefined || value === null || value === '') return false
  if (!isNaN(value)) return true
  if (typeof value !== 'string') return false // we only process strings from here
  return (
    !isNaN(value) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(value))
  ) // ...and ensure strings of whitespace fail
}

const numberSignClassname = val => {
  const cn = 'number' + ' '
  if (!isNumeric(val)) return ''
  if (+val === 0) return cn + 'zero'
  if (val < 0) return cn + 'negative'
  if (val > 0) return cn + 'positive'
}

export const getCellClassNames = (value, attributes) =>
  `${numberSignClassname(value)} ${attributes?.className || ''}`

const formatNumber = new Intl.NumberFormat('en-US')
export const num2numWithCommas = str => (isNumeric(str) ? formatNumber.format(str) : str)

const formatCurrency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
export const num2CurrencyWithCommas = str =>
  isNumeric(str) ? formatCurrency.format(str) : str
