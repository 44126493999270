export const coefKeys = [
    {
        id: "name",
        label: "Conservation Practice",
    },
    {
        id: "biomassCo2",
        label: "Biomass<br>Carbon",
    },
    {
        id: "fossilCo2",
        label: "Fossil<br>CO<sub style='font-size:.8em'>2</sub>",
    },
    {
        id: "biomassburningCo2",
        label: "Biomass<br>Burning CO<sub style='font-size:.8em'>2</sub>",
    },
    {
        id: "limingCo2",
        label: "Liming",
    },
    {
        id: "soilCarbonCo2",
        label: "Soil<br>Carbon",
    },
    {
        id: "directSoilN2O",
        label: "Direct<br>Soil N<sub style='font-size:.8em'>2</sub>O",
    },
    {
        id: "indirectSoilN2O",
        label: "Indirect<br>Soil N<sub style='font-size:.8em'>2</sub>O",
    },
    {
        id: "biomassburningN2O",
        label: "Biomass<br>Burning N<sub style='font-size:.8em'>2</sub>O",
    },
    {
        id: "soilCh4",
        label: "Soil<br>CH<sub style='font-size:.8em'>4</sub>",
    },
    {
        id: "biomassburningCh4",
        label: "Biomass<br>Burning CH<sub style='font-size:.8em'>4</sub>",
    },
    {
        id: "totalGhgCo2",
        label: "Total CO<sub>2</sub><br>Equivalent",
    },
    // {
    //     id: "totalGhgCo2_min",
    //     label: "Minimum Total<br>Emission Reductions",
    // },
    // {
    //     id: "totalGhgCo2_max",
    //     label: "Maximum Total<br>Emission Reductions",
    // },
];
