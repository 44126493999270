import { useState, useEffect } from "react";

export function useToggle(valueNaught) {
    const [currentValue, setValue] = useState(
        typeof valueNaught === "boolean" ? valueNaught : true
    );

    return [
        currentValue,
        (valueNext) => setValue((e) => (typeof valueNext === "boolean" ? valueNext : !e)),
    ];
}

export function useBreakPointPxOnScroll(defaultValues) {
    const scrollPxBreakPoints = defaultValues || [50, 290];
    const [breakPoint, setBreakPoint] = useState([-1, 0]);

    const listenScrollEvent = () => {
        const checkValue = [[-1, 0]];
        scrollPxBreakPoints.forEach((e, i) => {
            if (window.pageYOffset >= e) {
                checkValue.push([i, window.pageYOffset]);
            }
        });
        setBreakPoint(checkValue.slice(-1)[0]);
    };

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
        return () => window.removeEventListener("scroll", listenScrollEvent);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return breakPoint;
}
