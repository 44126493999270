// import ImageNext from 'next/image'

import React, { useState } from 'react'
import { Button } from '../Form'
import { getX } from '../../utils/getX'
import AxTable, { AxTableArrayOfObjects } from './AxTable'
import { ICON_LIST, Icon as IconSvg } from './Icon'
import { ModalJones } from './Modal'

export const Link = props => {
  const { href, style } = props
  return (
    <a
      href={href}
      style={style}
      className={`ax-axuserinput-Link ${props.className}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.label}
      {props.children}
    </a>
  )
}

export const HighlightedText = ({ txt, style }) => (
  <b style={{ ...{ fontSize: '1.051em' }, ...style }}> {txt} </b>
)

export const Icon = props => {
  const { icon, alt } = props
  if (ICON_LIST[props.icon]) return <IconSvg icon={icon} alt={alt} />
  switch (props.icon) {
    case 'info':
      return (
        <span
          {...getX.basicHtmlAttribs(props)}
          className={`icon ${props.icon} ${props.className}`}
        >
          {props.label}
          {props.children}
        </span>
      )

    default:
      return (
        <span
          {...getX.basicHtmlAttribs(props)}
          className={`icon ${props.className} ${props.icon}`}
        >
          {props.children}
        </span>
      )
  }
}

export const TabBox = props => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div role="tabpanel" className={`ax-tabbox ${props.className}`} style={props.style}>
      <ul
        className="ax-grid grow"
        style={{ gap: '2px', gridTemplateColumns: 'repeat(3, 1fr)' }}
      >
        {getX.map(
          (e, i) => (
            <li
              role="tab"
              aria-selected={i === activeTab}
              key={getX.reactKey(i, 'tabboxroleliwrapper')}
            >
              <Button
                key={getX.reactKey(i, 'Tabbed_comp_')}
                {...e}
                onClick={() => setActiveTab(i)}
                style={{ gap: '2em' }}
              />
            </li>
          ),
          props.tabs
        )}
      </ul>

      <div className="tabbox-content ax-flex two column">
        <div className="tabbox-header">{getX.value(props, 'tabs', activeTab, 'header')}</div>
        <div className="tabbox-body">{getX.value(props, 'tabs', activeTab, 'body')}</div>
        <div className="tabbox-footer ax-flex">
          {getX.joinJSX([props, 'tabs', activeTab, 'footer'], 'or', e => (
            <Button
              {...e}
              className="ax-link flex-parent"
              onClick={() =>
                getX.isNumeric(e.index)
                  ? setActiveTab(e.index)
                  : console.warn('no function', e.index)
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export const Table = props => {
  return props.tableKeys ? (
    <AxTableArrayOfObjects {...props} />
  ) : (
    <AxTable {...props} className={props.className} />
  )
}

export const Modal = props => <ModalJones {...props} />
