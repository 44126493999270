import React from "react";
import BasicTable from "../BasicTable";
import { coefKeys } from "./detseq.utils";
import Dialog from "../ui/Dialog";
import { CarbonSequestrationKey, TonnesCo2ePerYearLong } from "../index.jsx";
import "./DetailedSequestration.css";

import { TonnesCo2ePopup } from "../../constants/index.js";

const DetailedSequestration = ({ tableRows }) => {
  const tableKeys = coefKeys.map((k) => k.id);
  const tableHeader = coefKeys.reduce(
    (acc, k) => ({
      ...acc,
      [k.id]: <div dangerouslySetInnerHTML={{ __html: k.label }} />,
    }),
    {}
  );

  const disableDetailedResultsBtn =
    !(Array.isArray(tableRows) && tableRows.length > 0) || !tableRows.every((e) => e.area);

  return (
    <Dialog
      btnProps={{
        style: { color: "inherit" },
        children: "Detailed Report",
        disabled: disableDetailedResultsBtn,
        title: disableDetailedResultsBtn
          ? "Please Enter All Field Sizes"
          : "Detailed Carbon Sequestration and Greenhouse Gas Emission Reductions Report",
      }}
      header={
        <>
          Detailed Carbon Sequestration & Greenhouse Gas Emission Reductions
          <div style={{ textTransform: "none", paddingLeft: ".25em" }}>
            <TonnesCo2ePerYearLong />
            {/* <TonnesCo2ePopup /> */}
          </div>
        </>
      }
      footer={<CarbonSequestrationKey showNotEstimated={true} />}
      style={{ maxWidth: "1660px", margin: "auto" }}
    >
      <BasicTable
        {...{
          tableHeader,
          tableKeys,
          tableRows,
          className: "glob-detailed-sequestration-table",
        }}
      />
    </Dialog>
  );
};

export default DetailedSequestration;
