import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export default function CustomizedDialogs({
  btnProps,
  children,
  header,
  footer,
  sx,
  ...rest
}) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <button onClick={handleClickOpen} {...btnProps} />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={open}
        fullWidth
        sx={{
          ...{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: 'unset',
              },
            },
          },
          ...sx,
        }}
        {...rest}
      >
        {header && (
          <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="dialog-title">
              {header}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        )}
        <DialogContent style={{ position: 'relative' }} dividers>{children}</DialogContent>
        <DialogActions style={{ gap: '1em', display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>{footer}</div>
          <button autoFocus onClick={handleClose}>
            Close
          </button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  )
}
