import { useSelector } from "react-redux";

//mui components
import { Card, Slider, Typography } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
// theme styles
import MapLegend from "../MapLegend.jsx";

//redux
import { getMap } from "../../store/selectors.js";

import {
  CLIMATE_LAYER_NAME,
  MAPLAYER_ZOOM_NAUGHT,
  MAPLAYER_ZOOM_THRESHOLD,
  SOILS_LAYER_NAME,
} from "../../constants/mapLayers.js";
import { climateRegionColors, soilColors } from "../../assets/theme/mapLayers.js";
import theme from "../../assets/theme/theme.js";
import { useToggle } from "../../hooks";
import boxShadows from "../../assets/theme/box-shadow.js";

function MapLayersCard() {
  const [expandLayers, setExpandLayers] = useToggle(false);

  const map = useSelector(getMap);

  return (
    <Card style={{ boxShadow: boxShadows.buttonBoxShadowNeutral, zIndex: 100 }}>
      <button
        className="ax-flex"
        id="maplayersbutton"
        style={{
          gap: ".5em",
          border: "none",
          backgroundColor: expandLayers ? "var(--color-2)" : null,
          color: expandLayers ? "var(--color-light)" : null,
          padding: "0.3em .4em .2em .4em",
          maxHeight: "200px",
          overflow: "auto",
          borderRadius: "5px 4px 0 0",
        }}
        onClick={setExpandLayers}
      >
        <MapIcon />
        <span>Map Layers</span>
        <span style={{ flexGrow: 1 }}>
          {expandLayers && (
            <span className="ax-arrow down" style={{ marginRight: ".25em", float: "right" }} />
          )}
        </span>
      </button>
      {expandLayers &&
        (showZoomLevelWarning() ? (
          <div style={{ backgroundColor: "white", padding: ".5em" }}>
            <b>To see Layers, please zoom in</b>
            <ZoomBelowAcceptableLevel current={map.zoom} />
            <p style={{ margin: "0.05em 1em", fontSize: ".8em" }}>
              {/* <p style={{ margin: "0.5em 1em", fontSize: ".8em" }}> */} Climate and Soils
              layers are only displayed at zoom levels below <br />
              <b>100 km scale</b> to improve performance.
            </p>
          </div>
        ) : (
          <div style={{ backgroundColor: "white", maxHeight: "325px", overflow: "auto" }}>
            {map.climateLookups && !map.climateLoading ? (
              <MapLegend
                layerName={CLIMATE_LAYER_NAME}
                colors={climateRegionColors}
                lookups={map.climateLookups}
              />
            ) : (
              <LoadingPlaceHolder label={CLIMATE_LAYER_NAME} />
            )}
            {map.soilLookups && !map.soilLoading ? (
              <MapLegend
                layerName={SOILS_LAYER_NAME}
                subtitle="Soils are only loaded for the country in which the marker is located."
                colors={soilColors}
                lookups={map.soilLookups}
              />
            ) : (
              <LoadingPlaceHolder label={SOILS_LAYER_NAME} />
            )}
          </div>
        ))}
    </Card>
  );

  function showZoomLevelWarning() {
    return map.zoom <= MAPLAYER_ZOOM_THRESHOLD;
  }
}

export default MapLayersCard;

const LoadingPlaceHolder = (props) => (
  <div
    style={{
      padding: ".5em 2em",
      backgroundColor: "#eee",
      color: theme.palette.primary.slate,
    }}
  >
    Loading {props.label}...
  </div>
);

export const ZoomBelowAcceptableLevel = ({ current }) => {
  const percentageZoom = Math.floor(
    ((current - MAPLAYER_ZOOM_NAUGHT) * 100) / (MAPLAYER_ZOOM_THRESHOLD - MAPLAYER_ZOOM_NAUGHT)
  );
  function handleSliderChange(e, newValue) {
    const newZoom =
      (newValue * Math.abs(MAPLAYER_ZOOM_THRESHOLD - MAPLAYER_ZOOM_NAUGHT)) / 100 +
      MAPLAYER_ZOOM_NAUGHT;

    window.globalPlannerMap.getView().setZoom(newZoom + 0.03);
  }
  return (
    <Slider
      defaultValue={percentageZoom}
      aria-label="Transparency"
      value={percentageZoom}
      onChange={handleSliderChange}
      sx={{ maxWidth: "90%", margin: 0, padding: 0.5 }}
    />
  );
};
