import "./Header.css";

const Welcome = ({ welcomeObj }) => {
  const { header, body, footer } = { ...welcomeObj };

  const welcome = {
    width: "95%",
    maxWidth: "1080px",
    backgroundColor: "var(--color-light)",
    margin: "1em auto",
    padding: "1.5em",
    borderRadius: ".5em",
  };

  return (
    <div className="ax-header img">
      <br />
      {/* Welcome Message */}
      <div
        className="header-banner-container ax-header-transition"
        style={{
          ...welcome,
          ...{ marginTop: `${17}px`, opacity: ".95" },
          maxWidth: "var(--max-width-prose)",
        }}
      >
        <h2>{header}</h2>
        {body && body}
        {footer && <br />}
        {footer && footer}
      </div>
      <br />
    </div>
  );
};
export default Welcome;
