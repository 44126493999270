import { takeEvery, takeLatest } from "redux-saga/effects";

import { baselineSlice } from "../reducers/baseline";
import * as baselineSagas from "./baseline";

import { conservationPracticeSlice } from "../reducers/conservationPractice";
import * as conservationSagas from "./conservationPractice";

import { locationSlice } from "../reducers/location";
import * as locationSagas from "./location";

import { mapSlice } from "../reducers/map";
import * as mapSagas from "./map";

export function* rootSaga() {
    const relations = [
        [baselineSlice.actions, baselineSagas],
        [conservationPracticeSlice.actions, conservationSagas],
        [locationSlice.actions, locationSagas],
        [mapSlice.actions, mapSagas],
    ];

    const takeEverySagas = [conservationPracticeSlice.actions.selectConservationPractice.type];

    for (const [actions, sagas] of relations) {
        for (const [actionName, action] of Object.entries(actions)) {
            const saga = sagas[actionName];

            if (saga) {
                //saga types are just strings so we can safely use includes() here
                if (takeEverySagas.includes(action.type)) {
                    yield takeEvery(action.type, saga);
                } else {
                    yield takeLatest(action.type, saga);
                }
            }
        }
    }
}
