import React from "react";

/* =========== MENU IMAGES =========== */
import foreign_ag from "./assets/fas-white.svg";
import csu_dark from "./assets/csu-ram-stacked-dark.png";
import csu_light from "./assets/csu-ram-stacked-light.png";
import nrcs_dark from "./assets/usda_nrcs_dark.png";
import nrcs_light from "./assets/nrcs-white-lockup.svg";

import "./Branding.css";

const Branding = (props) => (
  <div className={`branding-container ${props.className}`}>
    <img alt={`usda_nrcs`} src={foreign_ag} height="50" width={50 * (482 / 87)} />
    <img
      alt={`usda_nrcs`}
      src={props?.light ? nrcs_light : nrcs_dark}
      height="40"
      width={40 * (2714 / 299)}
    />
    <img
      alt={`csu`}
      src={props?.light ? csu_light : csu_dark}
      height="60"
      width={60 * (1500 / 478)}
    />
  </div>
);

export default Branding;
