import React from "react";
import { Card, Fab } from "@mui/material";
import ConservationPracticeCard from "../../components/Cards/ConservationPracticeCard.jsx";
import ResultsCard from "../../components/Cards/ResultsCard.jsx";
import { CarbonSequestrationKey } from "../../components/index.jsx";
import "./Dashboard.css";
import { MapWrapper } from "./Maps.jsx";
import StepTitle from "../../components/Cards/StepTitle.jsx";
import LandUse from "../../components/LandUse/";
import { STEP_TITLES as STEP, TWENTY_YR_EXPLANATION } from "../../constants/index.js";
import FarmType from "../../components/Cards/FarmType.jsx";
import {
  BaselineLandUseInfo,
  ConservationPractInfo,
  FarmLocationInfo,
  FarmTypeInfo,
  ResultsCardInfo,
} from "../../components/Information/index.jsx";
import { ContactSupport } from "@mui/icons-material";

function Dashboard() {
  const stepComponentsArray = [
    {
      title: STEP.landLocation.title,
      subTitle: STEP.landLocation.subTitle,
      info: <FarmLocationInfo />,
      content: <MapWrapper />,
    },
    {
      title: STEP.farmType.title,
      content: <FarmType />,
      info: <FarmTypeInfo />,
    },
    {
      title: STEP.baselineLandUse.title,
      content: <LandUse />,
      info: <BaselineLandUseInfo />,
    },

    {
      title: STEP.conservationPractice.title,
      content: <ConservationPracticeCard />,
      info: <ConservationPractInfo />,
    },

    {
      title: STEP.ghgReduction.title,
      content: <ResultsCard />,
      info: <ResultsCardInfo />,
      footer: (
        <div
          className="results-20yr-explanation"
          style={{ fontSize: ".95em", fontWeight: "600", margin: "auto" }}
        >
          <div style={{ fontSize: ".8rem" }}>{TWENTY_YR_EXPLANATION}</div>
          <div style={{ margin: "auto", marginTop: "1em" }}>
            <CarbonSequestrationKey />
          </div>
        </div>
      ),
    },
  ];

  return (
    <main className="dashboard-step-wrap">
      {stepComponentsArray.map((e, i) => {
        return (
          <Card className={`dashboard-card ${e.className}`} key={`dashboard_step_card_${i}`}>
            {e.title && <StepTitle {...e} stepNumber={i + 1} style={e?.style?.steptitle} />}
            {e.content}
            {e.footer && <div className="dashboard-card-footer">{e.footer}</div>}
          </Card>
        );
      })}
      {/*       <Fab
        variant="extended"
        size="medium"
        sx={{
          position: "fixed",
          zIndex: 99,
          bottom: 10,
          right: 10,
          backgroundColor: "var(--color-1)",
          color: "black",
        }}
      >
        <ContactSupport sx={{ mr: 1 }} />
        Help
      </Fab> */}
    </main>
  );
}

export default Dashboard;
