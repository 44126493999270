import { useState } from "react";
import PlannerGlobalPage from "../PageLayout/PlannerGlobalPage";
import Welcome from "../PageLayout/Welcome";
import { Box, Link, Typography, Grid } from "@mui/material";
import PlannerGlobalModal from "../ui/Modal";
import Changelog from "./Changelog/Changelog";
import UseAgreement from "./UseAgreement";

const Documentation = () => {
  const documentationWelcome = {
    header: "COMET-Planner Global Documentation",
    body: (
      <p>
        Included below are links to assorted documentation associated with COMET Planner
        Global, as well as the other tools in the COMET suite. NOTE: This page is still in
        development, and can be expected to change.
      </p>
    ),
  };

  const [popupState, setLocalPopup] = useState({ displayPopup: false });

  const showChangelog = () => {
    setLocalPopup({
      displayPopup: true,
      close: () => setLocalPopup({ displayPopup: false }),
      header: "COMET-Planner Global Changelog",
      body: <Changelog />,
    });
  };

  const showUseAgreement = () => {
    setLocalPopup({
      displayPopup: true,
      close: () => setLocalPopup({ displayPopup: false }),
      header: "COMET-Planner Global Use Agreement",
      body: <UseAgreement />,
    });
  };

  return (
    <PlannerGlobalPage>
      <Welcome welcomeObj={documentationWelcome} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        px="3em"
        py="2em"
        marginLeft="2em"
        height="70.5vh"
      >
        <Grid container direction="column" width="1200px" maxWidth="100vw">
          <Typography variant="h2" fontSize="1.3em" fontWeight="bold">
            Resources
          </Typography>
          <Grid container direction="column" pl="2em">
            <Link style={{ cursor: "pointer" }} onClick={showChangelog}>
              Changelog
            </Link>
            {/* <Link style={{ cursor: "pointer" }} onClick={showUseAgreement}>Use Agreement</Link> */}
          </Grid>
          <Typography variant="h2" fontSize="1.3em" fontWeight="bold" pt="1.5em">
            COMET Tools Suite
          </Typography>
          <Grid container direction="column" pl="2em">
            <Link href="http://comet-planner.com/" rel="noopener roreferrer" target="_blank">
              COMET Planner
            </Link>
            <Link href="https://comet-farm.com/" rel="noopener roreferrer" target="_blank">
              COMET Farm
            </Link>
          </Grid>
        </Grid>

        {popupState && popupState.displayPopup && (
          <PlannerGlobalModal
            open={popupState.displayPopup}
            close={popupState.close}
            header={popupState.header}
            body={popupState.body}
          />
        )}
      </Box>
    </PlannerGlobalPage>
  );
};
export default Documentation;
