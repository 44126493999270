import ContactUs from "../components/Contact/ContactUs";
import Documentation from "../components/Documentation/Documentation";

const routes = [
    {
        route: "/contactus",
        component: <ContactUs />,
    },
    {
        route: "/documentation",
        component: <Documentation />,
    },
];

export default routes;
