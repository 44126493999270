import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lat: null,
    lon: null,
    inContinentalUS: false,
    climate: undefined, // Using undefined instead of null to prevent soils warning message from briefly appearing on page load
    soil: undefined,
    countryId: undefined,
    agroforestryRegionId: undefined,
};

export const locationSlice = createSlice({
    name: "location",
    initialState: initialState,
    reducers: {
        setLocation: (state, action) => {
            state.lat = action.payload.lat ?? state.lat;
            state.lon = action.payload.lon ?? state.lon;
            state.zoom = action.payload.zoom ?? state.zoom;
            state.inContinentalUS = action.payload.inContinentalUS ?? state.inContinentalUS;
        },
        receiveLocationData: (state, action) => {
            state.climate = action.payload.climate;
            state.soil = action.payload.soil;
            state.countryId = action.payload.countryId;
            state.agroforestryRegionId = action.payload.regionId;
        },
    },
});

export const { receiveLocationData, setLocation } = locationSlice.actions;

export const locationReducer = locationSlice.reducer;
