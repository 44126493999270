import { useEffect, useState } from 'react'

import { Icon } from '../'
import { getX } from '../../../utils/getX'
import { Button } from '../../Form'
import './Modal.css'
import ListenEscapeFunction from '../ListenEscapeFunction'
import { useToggle } from '../../../hooks'

import { Box, Modal, Slide, Typography } from '@mui/material'
import PropTypes from 'prop-types'

// Generic popup window
function PlannerGlobalModal({ open, close, header, ...rest }) {
  const { className, body, footer } = { ...rest }

  // State variable for showing/hiding the modal
  const [showModal, setShowModal] = useState(open)
  useEffect(() => setShowModal(open), [open])

  // Default close method, override if close is present
  let closeModal = () => setShowModal(false)
  if (getX.isFunct(close)) {
    closeModal = () => close()
  }

  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      sx={{ display: 'grid', placeItems: 'center' }}
    >
      <Slide direction="down" in={showModal} timeout={500}>
        <Box className={className ?? 'ax-modal'}>
          {/* Event listener, close the window if the user clicks outside */}
          <ListenEscapeFunction funct={closeModal} />
          {/* Header and close button */}
          <Box
            className="ax-flex header"
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: '1em' }}
          >
            <Typography variant="h2" fontSize="1.3rem" sx={{ marginBottom: 0 }}>
              {header}
            </Typography>
            <Button
              onClick={closeModal}
              label={<Icon icon="x" />}
              style={{ backgroundColor: 'transparent', border: 'none', padding: '0' }}
            />
          </Box>
          {/* Body */}
          {body && <Box className="ax-modal-body">{body}</Box>}
          {/* Footer */}
          {footer && <Box className='"ax-modal-body'>{footer}</Box>}
        </Box>
      </Slide>
    </Modal>
  )
}

// Default values
PlannerGlobalModal.defaultProps = {
  open: false,
  close: null,
  header: 'Planner Global Popup',
}

// Type declaration
PlannerGlobalModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  header: PropTypes.string,
}

export default PlannerGlobalModal

// Styled modal box, deprecated 4/3
export const ModalJones = props => {
  const { open, className, style, close, header, showX, body, footer } = { ...props }
  const [showModal, setShowModal] = useToggle(open || false)

  return (
    (showModal || open) && (
      <div className={`ax-modal ${className}`} style={{ ...style }}>
        <ListenEscapeFunction funct={close} />
        {header && (
          <div className="ax-flex header" style={{ padding: '1em' }}>
            <h2 style={{ flexGrow: 1 }}>{header}</h2>
            <div>
              {(showX ?? true) && (
                <Button
                  onClick={getX.isFunct(close) ? close : () => setShowModal(false)}
                  label={<Icon icon="x" />}
                  style={{ backgroundColor: 'transparent', border: 'none', padding: '0' }}
                />
              )}
            </div>
          </div>
        )}
        <div className="ax-modal-body">{body}</div>
        <div>{footer}</div>
      </div>
    )
  )
}

// Generic warning styled modal, deprecated 4/3
export const ModalWarning = props => {
  return (
    <ModalJones
      {...props}
      style={{ backgroundColor: '#d4c16e' }}
      header={
        <h3 style={{ color: 'var(--slate-800)' }}>
          <Icon icon="warning" style={{ marginRight: '.5em', color: '', fontSize: '1.2em' }} />
          {props.header}
        </h3>
      }
      body={<div style={{ backgroundColor: 'var(--light-color)' }}>{props.body}</div>}
    />
  )
}

// Nest modal inside mask to create popup, deprecated 4/3
export const ModalMasked = props => {
  return (
    props.open && (
      <div
        className="ax-modalmasked"
        style={{
          display: 'grid',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          inset: 0,
          alignItems: 'center',
          zIndex: props.zIndex || 3,
        }}
        /**
         * onClick is wrapped to make sure
         * clicking the Modal doesnt invoke
         * props.close()
         */
        onClick={e => {
          if (e.target === e.currentTarget) {
            props.close()
          }
        }}
      >
        <ModalJones {...props} />
      </div>
    )
  )
}
