import React from 'react'
import { getValue, getObject, getCellClassNames, num2numWithCommas } from './basictable.utils'
import './BasicTable.css'

export default function BasicTable(props) {
  const { caption, tableHeader, tableRows, tableFooter, className, tableKeys } = props
  if (!(Array.isArray(tableKeys) && tableKeys.length > 0)) return
  return (
    <table className={`basic-table ${className}`}>
      {caption && <caption>{caption}</caption>}
      {tableHeader && <BasicTableHeader {...{ tableKeys, rowData: tableHeader }} />}
      {Array.isArray(tableRows) && <BasicTableBody {...{ tableKeys, rowsData: tableRows }} />}
      {tableFooter && <BasicTableFooter {...{ tableKeys, rowData: tableFooter }} />}
    </table>
  )
}

const BasicTableHeader = props => {
  const { rowData, tableKeys } = props
  return (
    <thead>
      <tr {...rowData.attributes}>
        {tableKeys.map(key => {
          const th = getValue(rowData[key])
          const thAttributes = getObject(rowData[key])?.attributes
          return (
            <th
              {...thAttributes}
              key={`ax_thead_th_${key}`}
              className={`ax-thead-th ${getCellClassNames(th, thAttributes)}`}
            >
              {getValue(rowData[key])}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

const BasicTableBody = props => {
  const { rowsData, tableKeys } = props
  return (
    <tbody>
      {rowsData.map((row, index) => {
        return (
          <tr
            {...row?.attributes}
            key={`ax_tr_${index}`}
            className={`ax-table-tr ${row?.attributes?.className || ''}`}
          >
            {tableKeys.map(key => {
              const td = +getValue(row[key]) === 0 ? '0' : getValue(row[key])
              const tdAttributes = getObject(row[key])?.attributes
              return (
                <td
                  {...tdAttributes}
                  className={`ax-table-td ${getCellClassNames(td, tdAttributes)}`}
                  key={`ax_td_${key}`}
                >
                  {num2numWithCommas(td)}
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

const BasicTableFooter = props => {
  const { rowData, tableKeys } = props
  return (
    <tfoot>
      <tr {...rowData.attributes}>
        {tableKeys.map(key => {
          const td = getValue(rowData[key])
          const tdAttributes = getObject(rowData[key])?.attributes
          return (
            <td
              {...tdAttributes}
              key={`ax_tf_td_${key}`}
              className={`ax-tfoot-td ${getCellClassNames(td, tdAttributes)}`}
            >
              {getValue(rowData[key])}
            </td>
          )
        })}
      </tr>
    </tfoot>
  )
}
