import InfoIcon from "@mui/icons-material/Info";

import cropland_management from "../assets/crop-icons/cropland_management.png";
import grazing_lands from "../assets/crop-icons/grazing_lands.png";
import wood_cover from "../assets/crop-icons/wood_cover.png";
import Dialog from "../components/ui/Dialog";
import {
    TonnesCo2ePerYearLong,
    TonnesCo2ePerYearMedium,
    TonnesCo2ePerYearString,
} from "../components";

export const LARGE_FARM = "Commercial / Plantation";
export const SMALL_FARM = "Smallholder";

export const getFarmTypeNameById = (id) => {
    if (id === 1500) return SMALL_FARM;
    if (id === 1501) return LARGE_FARM;
    return null;
};

export const BASELINE_LANDUSE_TXT = "Current Land Use";
export const CULTIVATED_ANN_CROPLAND = "Cultivated Annual Croplands";
export const FARM_LOCATION = "Farm Location";
export const FARM_TYPE = "Farm Type";
export const PASTURE_RANGELANDS = "Pastures and Rangelands";
export const PERENNIAL_TREE_CROPS = "Perennial Tree Crops";

export const ACRE = "acre";
export const HECTARE = "hectare";

export const AREA = {
    MIN: 0,
    MAX: 1000,
};

export const LANDUSE_IMAGES = {
    1: cropland_management,
    2: grazing_lands,
    6: wood_cover,
    7: wood_cover,
};

export const STEP_TITLES = {
    landLocation: {
        title: `Find ${FARM_LOCATION}`,
        subTitle: "Zoom to your location and click the point on the map that you would like to evaluate",
    },
    farmType: { title: `Choose ${FARM_TYPE}` },
    conservationPractice: { title: "Select Conservation Practice" },
    ghgReduction: {
        title: "Carbon Sequestration Estimation",
        subTitle: "Enter the area to which the conservation practice would be applied",
    },
    baselineLandUse: { title: `Choose ${BASELINE_LANDUSE_TXT}` },
};

export const TonnesCo2ePopup = ({ btnProps }) => (
    <Dialog
        btnProps={{
            ...{
                style: { fontWeight: "normal", color: "inherit" },
                children: (
                    <span style={{ textWrap: "none" }}>
                        <TonnesCo2ePerYearMedium />
                        {" * "}
                        <InfoIcon />
                    </span>
                ),
            },
            ...btnProps,
        }}
        header={
            <span style={{ textTransform: "none" }}>
                Carbon Sequestration (<TonnesCo2ePerYearLong />)
            </span>
        }
        style={{ maxWidth: "800px", margin: "auto" }}
    >
        Approximate carbon sequestration and greenhouse gas emission reduction estimates are
        given in{" "}
        <b>
            tonnes CO<sub>2</sub> equivalent per year
        </b>
        , where:
        <div style={{ padding: "0 1em" }}>
            <p>
                <b>tonnes = Metric Tonnes</b> (same as Megagrams) <br />
                Metric Tonnes are similar to English (or ‘short’) tons; 1 Metric Ton = 1.1
                English (short) tons
            </p>

            <p>
                <b>
                    CO<sub>2</sub> equivalent = Carbon Dioxide Equivalent
                </b>
                <br />
                Carbon dioxide equivalent is a measure used to compare the emissions from
                various greenhouse gases, based upon their global warming potential. Carbon
                dioxide equivalents
                <br /> are used in COMET-Planner to allow users to compare emission reductions
                of carbon dioxide, nitrous oxide and methane in standardized units.
            </p>
        </div>
        <p>
            Results reflect annualized carbon stock changes after 20 years of conservation
            practice adoption, compared to a baseline carbon stock. A positive value indicates
            carbon sequestration due to adoption of a conservation practice.
        </p>
    </Dialog>
);

export const RESULTS_HEADERS = [
    "Conservation Practice",
    <span title={STEP_TITLES.ghgReduction.subTitle}>
        Field Size{" "}
        <span style={{ whiteSpace: "pre" }}>
            (hectares) <InfoIcon />
        </span>
    </span>,
    <>
        <span className="desktop-only">Carbon Sequestration </span>
        <TonnesCo2ePopup />
    </>,
];

export const CONSERVATION_PRACTICE_HEADERS = [
    /**
     * NOTE: not in use as of 04/2023
     * saving if needed later
     */
    "Baseline Land Use Class",
    "Baseline Input Class",
    "Baseline Management Class",
    "Scenario Land Use Class",
    "Scenario Input Class",
    "Scenario Management Class",
];

export const RESULTS_HEADER_PDF = [...RESULTS_HEADERS].map(
    // export const RESULTS_HEADER_PDF = [...RESULTS_HEADERS, ...CONSERVATION_PRACTICE_HEADERS].map(
    (e, i) => {
        if (i === 1) return "Field Size (ha)";
        if (i === 2) return `Carbon Sequestration (${TonnesCo2ePerYearString})*`;
        if (i === 3) return "Carbon Sequestration (Tonnes CO2e over 20 years)";
        return e;
    }
);

export const TWENTY_YR_EXPLANATION = `*Results reflect annualized carbon stock changes after 20 years of conservation practice adoption, compared to a baseline carbon stock.
  A positive value indicates carbon sequestration due to adoption of a conservation practice.`;

export const NA_SOILS_DATA_INFO = `**The IPCC methods used in this tool apply to mineral soils only. The ${FARM_LOCATION} selected is not reported as a mineral soil.`;

export const DATA_BASE_NO_SOILS_DATA = "No soils data";

/**
 * AS of 2023/04 valid and invalid soil type ids are sequential and perfectly balanced 6 and 6
 */
export const VALID_SOIL_IDS = Array.from({ length: 6 }, (_, i) => i + 10101);
export const INVALID_SOIL_IDS = Array.from({ length: 6 }, (_, i) => i + 10107);
