import Input, { Button } from "../Form/index.jsx";
import { useSelector, useDispatch } from "react-redux";

/* ==  MUI/MATERIAL COMPONENTS == */
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "tss-react/mui";
import {
  Alert,
  AlertTitle,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

/* == THEME STYLES == */
import componentStyles from "../../assets/theme/views/admin/dashboard.js";

/* ==  REDUX == */
import {
  getBaselineSelectedId,
  getConservationList,
  getBaselineList,
  getConservationSelectedIds,
  getLocation,
  getResults,
  getFarmTypeId,
  getAgroforestryList,
} from "../../store/selectors.js";
import { clearSelectedPractices, updateAreaInput } from "../../reducers/conservationPractice";

/* == OTHER IMPORTS == */
import { ClearSelectionButton } from "../ui/HoverSlide/ToolTipSpan.js";
import { useToggle } from "../../hooks";
import { Methodology } from "../Information";
import {
  AREA,
  RESULTS_HEADERS,
  RESULTS_HEADER_PDF,
  TonnesCo2ePopup,
  VALID_SOIL_IDS,
  getFarmTypeNameById,
} from "../../constants";
import {
  calcCO2ePerYear,
  displayAgroforestryPractice,
  displayPractice,
  getLandUseIdFromBaselineId,
  calcBiomassCarbonPerYear,
  calcSoilCarbonPerYear,
  num2numWithCommas,
  numberSignClassname,
} from "../../utils/helpers";
import { useState, useEffect } from "react";
import { getX } from "../../utils/getX";
import PdfReportDownload from "../PdfReportDownload";
import DetailedSequestrationTable from "../DetailedSequestration";
import { TonnesCo2ePerYearShort } from "../index.jsx";
/* == CHILD COMPONENTS == */
const LabelValue = (props) =>
  props.value && (
    <div
      style={{
        verticalAlign: "middle",
        borderTop: "0",
        fontWeight: 400,
      }}
    >
      <span style={{ marginLeft: "0.7rem" }}>
        {props.label + "\u00A0"}|
        <b style={{ fontWeight: "600" }}>{"\u00A0" + props.value || "N/A"}</b>
      </span>
    </div>
  );

const BaselineInfo = (props) => (
  <LabelValue styles={{ fontSize: "1.01em", margin: "0", padding: "0" }} {...props} />
);

const NoSoilsData = (props) => (
  <Alert severity="info">
    <AlertTitle>
      <h3>
        Incompatible Soil Found -
        <span style={{ color: "#351212", fontSize: ".9em", paddingLeft: ".5em" }}>
          Please select a different Location
        </span>
      </h3>
    </AlertTitle>
    <p>
      The IPCC methods used in this tool only apply to mineral soils. The soil type for your
      selected location
      {props.label && (
        <>
          , <em>{props.label}</em>,{" "}
        </>
      )}
      is not a mineral soil, so no calculations could be performed.
    </p>
    <p>If needed, use the Soils layer on the map above to see soil types in your area.</p>
  </Alert>
);

const SiteInfo = (props) => (
  <h2 className="ax-flex" style={{ textAlign: "center", width: "100%", margin: "1em auto" }}>
    <LabelValue label={"Climate"} value={props.climate} />
    <LabelValue label={"Soil"} value={props.soil} />
  </h2>
);

/* == DEFAULT COMPONENT == */
function ResultsCard() {
  /* == MATERIAL UI == */
  const { classes } = makeStyles()(componentStyles)();

  /* == REDUX == */
  const baselineList = useSelector(getBaselineList);
  const baselineSelectedId = useSelector(getBaselineSelectedId);
  const landUseClassId = getLandUseIdFromBaselineId(useSelector(getBaselineSelectedId));
  const conservationList = useSelector(getConservationList);
  const agroforestryList = useSelector(getAgroforestryList);
  const conservationSelectedIds = useSelector(getConservationSelectedIds);
  const reduxResults = useSelector(getResults);
  const location = useSelector(getLocation);
  const dispatch = useDispatch();
  const farmTypeId = useSelector(getFarmTypeId);

  /* == HOOKS == */
  const [showMethodology, toggleResults_Methodology] = useToggle(false);
  const [results, setResults] = useState(reduxResults);
  const [clearedLabel, toggleClearedLabel] = useToggle();
  const [downloadingLabel, toggleDownloadingLabel] = useToggle(false);

  useEffect(() => {
    const res = getX.arrayCloneSort("name", reduxResults).map((e) => {
      return {
        ...e,
        co2ePerYear: calcCO2ePerYear(e),
        conservationPractice: findSelectedPractice(e),
      };
    });
    setResults(res);
  }, [reduxResults, conservationList, agroforestryList]);

  // TODO need a better way of deciding which display check to use
  const showResultsRow = (item) => {
    return (
      (item.emissionSubcategory === "soilCarbon"
        ? displayPractice(item, location, landUseClassId)
        : displayAgroforestryPractice(item, location, baselineSelectedId, farmTypeId)) &&
      conservationSelectedIds.includes(item.id)
    );
  };

  const findSelectedPractice = (item) =>
    conservationList.find((cl) => cl.id === item.id) ||
    agroforestryList.find((ap) => ap.id === item.id);

  const soilDataViolationBool = () => !VALID_SOIL_IDS.includes(location?.soil?.id);

  const disableDownload = () => {
    return (
      !getX.checkArray(reduxResults) ||
      soilDataViolationBool() ||
      reduxResults.some((e) => e.error !== null)
    );
  };

  const resultsSelected = getX
    .array(results)
    .filter((e) => showResultsRow(findSelectedPractice(e)));

  function getBaselineName() {
    return getX.array(baselineList).find((e) => e.id === baselineSelectedId)?.name;
  }

  const ResultsMethodologyToggleButtons = () => (
    <>
      <Button
        style={{ borderRadius: "5px 5px 0 0" }}
        className={!showMethodology ? "active" : ""}
        label={"Results"}
        onClick={() => toggleResults_Methodology(false)}
      />
      <Button
        style={{ borderRadius: "5px 5px 0 0" }}
        className={showMethodology ? "active" : ""}
        label={"Methodology"}
        onClick={() => toggleResults_Methodology(true)}
      />
    </>
  );
  const PdfDownloadButtons = () => (
    <div className="ax-children float-right" style={{ flexGrow: 1, marginBottom: ".25em" }}>
      <ClearSelectionButton
        onClick={() => {
          toggleClearedLabel(false);
          setTimeout(toggleClearedLabel, 2000);
          dispatch(clearSelectedPractices());
        }}
        label={clearedLabel ? "Clear Selections" : "Selections Cleared..."}
        style={{ margin: "0 1em" }}
      />

      <PdfReportDownload
        disabled={disableDownload()}
        label={downloadingLabel ? "Downloading..." : "Download"}
        onClick={() => {
          toggleDownloadingLabel(true);
          setTimeout(toggleDownloadingLabel, 2000);
        }}
        dataRaw={results}
        columns={RESULTS_HEADER_PDF}
        body={resultsSelected.map((e) => [
          e?.name,
          e?.area,
          getX.isObject(e.co2ePerYear) ? "Field Size Out of Bounds" : e.co2ePerYear,
          /**
                 * TODO: DO NOT REMOVE (for now 2023/04)
                 * Stakeholders have asked for more columns reported. 
                 * some, none, or all may apply later.
                 e.co2ePer20year,
                 e?.conservationPractice?.baselineLandUseClass?.name,
                 e?.baselineInputClass?.name,
                 e?.baselineManagementClass?.name,
                 e?.conservationPractice?.scenarioLandUseClass?.name,
                 e?.scenarioInputClass?.name,
                 e?.scenarioManagementClass?.name,
                */
        ])}
        location={[
          ["lat", location?.lat],
          ["lon", location?.lon],
        ]}
        baseline={getBaselineName()}
        farmType={getFarmTypeNameById(farmTypeId)}
        climate={location?.climate?.name}
        soil={location?.soil?.name}
      />
      <DetailedSequestrationTable
        tableRows={resultsSelected.map((e) => ({
          ...e,
          co2ePerYear: calcCO2ePerYear(e),
          conservationPractice: findSelectedPractice(e),
          co2_mean: calcCO2ePerYear(e),
          soilCarbonCo2: calcSoilCarbonPerYear(e),
          biomassCo2: calcBiomassCarbonPerYear(e),
          totalGhgCo2: calcCO2ePerYear(e),
          totalGhgCo2_min: calcCO2ePerYear(e),
          totalGhgCo2_max: calcCO2ePerYear(e),
        }))}
      />
    </div>
  );

  return (
    <Box style={{ maxWidth: "100%", margin: " .75em" }}>
      <div className="ax-flex resultscard-btns clear-me-floats">
        <ResultsMethodologyToggleButtons />
        <PdfDownloadButtons />
      </div>
      <SiteInfo climate={location?.climate?.name} soil={location?.soil?.name} />
      {showMethodology && <Methodology />}
      {!showMethodology && (
        <div style={{ overflow: "auto" }}>
          {farmTypeId &&
            landUseClassId &&
            location?.lat &&
            location?.lon &&
            (soilDataViolationBool() ? (
              <NoSoilsData label={location?.soil?.name} />
            ) : (
              <TableContainer
                className="resultscard-table"
                style={{
                  width: "unset",
                  margin: "1em auto",
                  overflow: "auto",
                }}
              >
                <Box component={Table} alignItems="center" marginBottom="0 !important">
                  <TableHead>
                    <TableRow>
                      {RESULTS_HEADERS.map((header, i) => (
                        <TableCell
                          className={(() => {
                            if (RESULTS_HEADERS.length - 1 === i)
                              return "results-table-tco2e-cell";
                            if (0 === i) return "results-table-practice-cell";
                            if (1 === i) return "field-size-input-cell";
                          })()}
                          key={i + "_table_header"}
                          classes={{
                            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                          }}
                          style={{ textTransform: "none", whiteSpace: "normal" }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Make a copy of the array in order to sort it by id */}
                    {getX.arrayCloneSort("name", results).map((item) => {
                      const conservationPractice = findSelectedPractice(item);
                      const conservationBaselineList = [
                        {
                          label: "Baseline Land Use Class",
                          value: item.baselineLandUseClass?.name,
                        },
                        {
                          label: "Baseline Input Class",
                          value: item.baselineInputClass?.name,
                        },
                        {
                          label: "Baseline Management Class",
                          value: item.baselineManagementClass?.name,
                        },
                        {
                          label: "Scenario Land Use Class",
                          value: item.scenarioLandUseClass?.name,
                        },
                        {
                          label: "Scenario Input Class",
                          value: item.scenarioInputClass?.name,
                        },
                        {
                          label: "Scenario Management Class",
                          value: item.scenarioManagementClass?.name,
                        },
                      ];
                      return (
                        showResultsRow(conservationPractice) && (
                          <TableRow key={item.id}>
                            <TableCell
                              className="results-table-practice-cell"
                              classes={{
                                root:
                                  classes.tableCellRoot + " " + classes.tableCellRootBodyHead,
                              }}
                              style={{ maxWidth: "200px" }}
                              component="th"
                              variant="head"
                              scope="row"
                            >
                              <details
                                className={
                                  conservationBaselineList.some((e) => e.value)
                                    ? ""
                                    : "no-marker"
                                }
                              >
                                <summary>{conservationPractice?.name || ""}</summary>
                                {conservationBaselineList.map((e, i) => (
                                  <div
                                    className={"expendable"}
                                    key={`${i}_baselinestuff`}
                                    style={{ marginLeft: ".5em", fontSize: ".9em" }}
                                  >
                                    <BaselineInfo {...e} classes={classes} />
                                  </div>
                                ))}
                              </details>
                            </TableCell>
                            <TableCell
                              className="field-size-input-cell"
                              classes={{ root: classes.tableCellRoot }}
                            >
                              <Input
                                className="field-size-input"
                                type="number"
                                step="any"
                                style={{ textAlign: "center", width: "8em" }}
                                min={AREA.MIN}
                                max={AREA.MAX}
                                placeholder="Enter Field Size"
                                defaultValue={item.area || null}
                                onChange={(e) => {
                                  dispatch(
                                    updateAreaInput({
                                      id: item.id,
                                      area: parseFloat(e.target.value),
                                    })
                                  );
                                }}
                              />
                              <div
                                style={{
                                  fontWeight: "bold",
                                  margin: "auto",
                                  marginTop: ".5em",
                                }}
                                className={`practice-name-tco2e-value`}
                              >
                                <TonnesCo2ePopup
                                  btnProps={{
                                    children: (
                                      <>
                                        <span
                                          className={`${numberSignClassname(
                                            item.co2ePerYear
                                          )}`}
                                        >
                                          {num2numWithCommas(item.co2ePerYear)}
                                        </span>{" "}
                                        <TonnesCo2ePerYearShort /> <InfoIcon />
                                      </>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              className={`results-table-tco2e-cell ${numberSignClassname(
                                item.co2ePerYear
                              )}`}
                              classes={{ root: classes.tableCellRoot }}
                              style={{
                                color: isNaN(item.co2ePerYear) ? "var(--cancel-color)" : "",
                              }}
                            >
                              {num2numWithCommas(item.co2ePerYear)}
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                  </TableBody>
                </Box>
              </TableContainer>
            ))}
        </div>
      )}
    </Box>
  );
}

export default ResultsCard;
