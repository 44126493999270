import React, { useEffect } from 'react'

const ListenEscapeFunction = ({ funct }) => {
  useEffect(() => {
    window.addEventListener('keydown', handleKeyShortcutToggleMenu)

    return () => {
      window.removeEventListener('keydown', handleKeyShortcutToggleMenu)
    }
  })

  function handleKeyShortcutToggleMenu(e) {
    if (typeof funct === 'function' && e.key === 'Escape') funct()
  }
  return <div className="empty-listen-escapekey-function"></div>
}

export default ListenEscapeFunction
