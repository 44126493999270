import React from "react";
import theme from "../../assets/theme/theme";
import PlannerGlobalModal from "../ui/Modal";
import { useToggle } from "../../hooks";

const StepTitle = (props) => {
  const [openModal, toggleModal] = useToggle(false);
  return (
    <>
      <span
        open={false}
        className="cpgl-steptitle"
        style={{
          ...{
            width: "100%",
            padding: "0.5em ",
            borderBottom: "#eee 1px solid",
            top: "0",
            backgroundColor: "inherit",
          },
          ...props.style,
        }}
      >
        <h2
          style={{
            margin: 0,
            padding: 0,
            display: "flex",
            gap: "1em",
            alignItems: "center",
            color: theme.palette.primary.slate,
          }}
        >
          <span className="global-step-integer">{props.stepNumber}</span>
          <span style={{ marginLeft: "2.5em", color: "var(--color-2)" }}>{props.title}</span>
          <span className="desktop-only" style={{ flexGrow: 1 }}>
            {props.info && (
              <button
                onClick={toggleModal}
                className="cpg-info-button"
                style={{
                  fontSize: ".9em",
                  float: "right",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <span className="icon info ax-circle" /> */}
                <InfoIconSolid />
              </button>
            )}
          </span>
        </h2>
        <div style={{ margin: "0.5em .25em" }}>{props.subheader}</div>
      </span>
      <PlannerGlobalModal
        open={openModal}
        close={toggleModal}
        header={props.title}
        body={props.info}
      />
      {props.subTitle && <SubTitle subTitle={props.subTitle} />}
    </>
  );
};

export default StepTitle;

const SubTitle = ({ subTitle }) => <h4 style={{ margin: ".5em" }}>{subTitle}</h4>;

const InfoIconSolid = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 1024 1024"
    height="2em"
    width="2em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm32 664c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V456c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272zm-32-344a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z"></path>
  </svg>
);
