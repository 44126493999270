import React, { useState } from "react";

import { Card, IconButton, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { transform } from "ol/proj.js";

export default function LocationSearchbar(props) {
  const [searchText, setSearchText] = useState("");

  const handleTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const checkKeyPress = (e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      e.preventDefault();
      searchLocation();
    }
  };

  const searchLocation = (event) => {
    fetch(
      "https://dev.virtualearth.net/REST/v1/Locations?query=" +
        encodeURIComponent(searchText) +
        "&key=AodaDoXgognX4vhPqaouMx44frx2G9a5Swio1r9aGK0iOiUNyYu9jnqpHMT0_1hL", //todo: get our own maps key?
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          try {
            var base64data = reader.result;
            //chrome and firefox put different things at the start of the string, but "base64," seems to be consistent
            const json = atob(base64data.split("base64,")[1]);
            const result = JSON.parse(json);
            var lat = result.resourceSets[0].resources[0].geocodePoints[0].coordinates[0];
            var long = result.resourceSets[0].resources[0].geocodePoints[0].coordinates[1];
            var boundingBox = result.resourceSets[0].resources[0].bbox;
            window.globalPlannerMap
              .getView()
              .setCenter(transform([long, lat], "EPSG:4326", "EPSG:3857"));
            window.globalPlannerMap.getView().setZoom(getZoomLevel(boundingBox));
          } catch (error) {
            console.warn("location search error: ", error);
            //todo: pop up "location not found" warning message so the user has some feedback
          }
        };
      });
  };

  const getZoomLevel = (boundingBox) => {
    const yDifference = boundingBox[0] - boundingBox[2];
    const xDifference = boundingBox[1] - boundingBox[3];
    const distance = Math.sqrt(xDifference * xDifference + yDifference * yDifference);

    //4PL curve provided by mycurvefit.com from sample points
    return -144.3429 + 180.11539 / (1 + Math.pow(distance / 87703640000, 0.07238359));
  };

  return (
    <Card id="locationSearchPanel">
      <TextField
        variant="standard"
        placeholder="Search for a location"
        defaultValue=""
        id="locationSearchField"
        onKeyDown={checkKeyPress}
        onChange={handleTextChange}
      />
      <IconButton onClick={searchLocation}>
        <SearchIcon style={{ minWidth: "1.25rem", minHeight: "1.25rem" }} />
      </IconButton>
    </Card>
  );
}
