import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// @mui/material components
import { Alert, AlertTitle, Box, Slide } from "@mui/material";

// openlayers components
import "ol/ol.css";
import Draw from "ol/interaction/Draw";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";
import { defaults, ScaleLine } from "ol/control";
import { toLonLat } from "ol/proj";
import { containsCoordinate } from "ol/extent.js";

//redux
import { setLocation } from "../../reducers/location.js";
import { setView } from "../../reducers/map.js";
import { getLocation } from "../../store/selectors.js";
import {
  BASE_LAYER_NAME,
  COUNTRIES_LAYER_NAME,
  HIGHLIGHTED_COUNTRY_LAYER_NAME,
  MAPLAYER_ZOOM_THRESHOLD,
  USA_LAYER_NAME,
} from "../../constants/mapLayers.js";
import MapLayersCard from "../../components/Cards/MapLayersCard.jsx";
import { ModalMasked } from "../../components/ui/Modal/index.js";
import { useToggle } from "../../hooks/index.js";
import { ContinentalUSMessage } from "../../components/Information/index.jsx";
import { Button } from "../../components/Form/index.jsx";
import MapToolbar from "../../components/MapToolbar/MapToolbar.jsx";
import { INVALID_SOIL_IDS } from "../../constants/index.js";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import { Icon, Stroke, Style } from "ol/style.js";
import mapPin from "./assets/map-pin.svg";

const MapWrapper = () => {
  const dispatch = useDispatch();
  const location = useSelector(getLocation);
  // const inContinentalUS = useSelector(getInContinentalUS);
  const [openModal, toggleModal] = useToggle(false);
  const [modalDismissed, setModalDismissed] = useState(false);

  function checkIncompatibleSoil() {
    return !checkMissingSoil(location) && INVALID_SOIL_IDS.includes(location.soil?.id);
  }

  function checkMissingSoil() {
    return location.soil === null;
  }

  function checkMissingClimate() {
    return location.climate === null;
  }

  function showDataWarning() {
    return checkMissingClimate() || checkMissingSoil() || checkIncompatibleSoil(location);
  }

  function locationDataMessage() {
    // useful test locations:
    // no climate data, incompatible soil data: any ocean
    // no climate data but found soil data: lat: 66.50475814583663, lon: 70.69186113219692 (Kara Sea, Russia)
    // found climate data but not soil data: lat: 40.73956247295848, lon: -73.24428601622759 (Westfield South Shore Mall, Long Island, NY)
    var messages = [
      <>
        Soil: <em> {location?.soil?.name} </em>
      </>,
    ];

    if (checkIncompatibleSoil()) {
      messages.push(
        <>
          Incompatible soil type: <em> {location?.soil?.name} </em>
        </>
      );
    }

    if (!checkMissingClimate() || !checkMissingSoil()) {
      messages.push(
        <>
          Climate:
          <em> {location?.climate?.name} </em>
        </>
      );
    }

    return (
      <Alert severity="info" className="maps-location-message maps-missing-data-warning-wrap">
        <ul className="maps-missing-data-warning dashed" style={{ marginTop: "-.55rem" }}>
          {messages.map((e, i) => (
            <li key={i} style={{ padding: ".25em" }}>
              {e}
            </li>
          ))}
        </ul>
      </Alert>
    );
  }

  function missingDataMessage() {
    // useful test locations:
    // no climate data, incompatible soil data: any ocean
    // no climate data but found soil data: lat: 66.50475814583663, lon: 70.69186113219692 (Kara Sea, Russia)
    // found climate data but not soil data: lat: 40.73956247295848, lon: -73.24428601622759 (Westfield South Shore Mall, Long Island, NY)

    var messages = [];

    if (checkIncompatibleSoil()) {
      messages.push(
        <>
          Incompatible soil type: <em> {location?.soil?.name} </em>
        </>
      );
    }

    if (checkMissingClimate() || checkMissingSoil()) {
      messages.push(
        <>
          No {checkMissingClimate() && <b> Climate </b>}
          {checkMissingClimate() && checkMissingSoil() && " or "}
          {checkMissingSoil() && <b> Soil </b>}
          data.
        </>
      );
    }

    return (
      <Alert
        severity="warning"
        className="maps-location-message maps-missing-data-warning-wrap"
        // className="maps-missing-data-warning-wrap"
      >
        <AlertTitle>
          <h3>Please select a different Location</h3>
        </AlertTitle>

        <ul className="maps-missing-data-warning dashed" style={{ marginBottom: ".5em" }}>
          {messages.map((e, i) => (
            <li style={{ padding: "0 .5em" }} key={i}>
              {e}
            </li>
          ))}
        </ul>

        <details style={{ pointerEvents: "auto" }}>
          <summary className="">More Info...</summary>
          <p>
            The location you have selected has no climate data or has a soil type that is not
            applicable to this tool's calculations. To view the climate and soil data at your
            selected location, you can use the Map Layers legend at the top right of the map.
          </p>
        </details>
      </Alert>
    );
  }

  function getLayer(layerName) {
    return window.globalPlannerMap.getAllLayers().find((l) => l.get("name") === layerName);
  }

  const dismissModal = () => {
    toggleModal();
    setModalDismissed(true);

    var usLayer = getLayer(USA_LAYER_NAME);

    if (usLayer) {
      usLayer.setVisible(false);
    }
  };

  useEffect(() => {
    toggleModal(location.inContinentalUS === true && !modalDismissed);
    //eslint-disable-next-line
  }, [location.inContinentalUS]);

  useEffect(() => {
    // Alternative method - slower, but doesn't require creating a new map instance
    if (!window.globalPlannerMap) {
      const map = new Map({
        target: "map",
        view: new View({
          center: [1200000, 2300000],
          zoom: 0,
        }),
        controls: defaults().extend([new ScaleLine()]),
        layers: [
          new TileLayer({
            visible: true,
            preload: Infinity,
            source: new BingMaps({
              key: "AodaDoXgognX4vhPqaouMx44frx2G9a5Swio1r9aGK0iOiUNyYu9jnqpHMT0_1hL", //todo: get our own maps key? This key comes from (and is billed to) Comet Farm
              imagerySet: "AerialWithLabels",
              maxZoom: 19,
            }),
            properties: {
              name: BASE_LAYER_NAME,
            },
          }),
        ],
      });

      const countryOverlay = new VectorLayer({
        source: new VectorSource(),
        // stroke color is --var-color-2, openlayers just won't let me use css variables here
        style: function (feature, resolution) {
          return new Style({
            stroke: new Stroke({
              color: "hsl(196 64% 24%)", // stroke color is --var-color-2, openlayers just won't let me use css variables here
              width: 3,
            }),
          });
        },
        minZoom: MAPLAYER_ZOOM_THRESHOLD,
        properties: {
          name: HIGHLIGHTED_COUNTRY_LAYER_NAME,
        },
        visible: false,
      });
      map.addLayer(countryOverlay);

      const pinLayer = new VectorLayer({
        source: new VectorSource(),
      });
      map.addLayer(pinLayer);

      const pinStyle = new Style({
        image: new Icon({
          src: mapPin,
          id: "#mac-daddy",
          anchor: [0.5, 0.95],
        }),
      });

      const draw = new Draw({
        source: pinLayer.getSource(),
        type: "Point",
        style: new Style({}),
      });
      map.addInteraction(draw);

      //event type found at https://openlayers.org/en/v7.2.2/apidoc/module-ol_MapEvent-MapEvent.html
      map.on("moveend", (event) => {
        const centerCoords = map.getView().getCenter();

        //update the highlighted country boundary
        let currentCountry;
        const countriesLayer = getLayer(COUNTRIES_LAYER_NAME);
        if (countriesLayer) {
          const features = countriesLayer.getSource().getFeaturesAtCoordinate(centerCoords);
          currentCountry = features.length > 0 ? features[0] : null;
          const overlay = countryOverlay.getSource();

          //only update the overlay if we're moving outside the current country
          if (overlay.getFeatures()[0]?.get("id") !== currentCountry?.get("id")) {
            overlay.clear();

            if (currentCountry) {
              overlay.addFeature(currentCountry);
            }
          }
        }

        const lonLat = toLonLat(centerCoords, map.getView().getProjection());
        const zoomLevel = map.getView().getZoom();

        //kick off action to set zoom level
        dispatch(
          setView({
            centerLat: lonLat[1],
            centerLon: lonLat[0],
            zoom: zoomLevel,
            countryId: currentCountry?.get("lookupId"),
          })
        );
      });

      draw.on("drawend", (event) => {
        //remove the last pin
        const pinLayerSource = pinLayer.getSource();
        for (var feature of pinLayerSource.getFeatures()) {
          if (feature !== event.feature) {
            pinLayerSource.removeFeature(feature);
          }
        }

        //set the style on the new pin
        event.feature.setStyle(pinStyle);

        //get the coordinates of the new pin
        const coordinates = event.feature.get("geometry").getCoordinates();

        //check if we're in the continental US
        const usLayer = getLayer(USA_LAYER_NAME);
        const inContinentalUS = usLayer
          ? usLayer
              .getSource()
              .getFeaturesAtCoordinate(coordinates)
              .some((f) => f.get("lookupId") === 959) &&
            containsCoordinate(usLayer.getExtent(), coordinates)
          : false;

        var lonLat = toLonLat(coordinates, map.getView().getProjection());

        //kick off action to set lat/lon (and saga to fetch soil/climate info and rerun calculations)
        dispatch(
          setLocation({
            lat: lonLat[1],
            lon: lonLat[0],
            inContinentalUS: inContinentalUS,
          })
        );
      });

      map.on("click", (event) => {
        map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {});
      });

      window.globalPlannerMap = map;
    } else {
      window.globalPlannerMap.setTarget("tempMap");
      window.globalPlannerMap.setTarget("map");
    }
  }, []); // Run on the inital render, each time the page loads

  const containerRef = React.useRef(null);

  return (
    <div className="dashboard-map">
      <div className="maplayerscard-wrap">
        <MapLayersCard />
      </div>
      <Box
        maxHeight={"80vh"}
        height="550px"
        position="relative"
        width="100%"
        overflow="hidden"
        borderRadius=".375rem"
        id="map"
      >
        {/* === UnComment <CrossHairs /> component to show the "crosshairs" for the center of the map viewport ===*/}
        {/* <CrossHairs /> */}

        <WarningInfoSlide
          slideIn={showDataWarning()}
          containerRef={containerRef.current}
          content={missingDataMessage()}
        />

        <WarningInfoSlide
          slideIn={
            !showDataWarning() &&
            location?.soil !== undefined &&
            location?.climate !== undefined
          }
          containerRef={containerRef.current}
          content={locationDataMessage()}
        />

        <MapToolbar />

        {/* Super hacky workaround, when we route back to this page the map swaps to this component, then back to the actual map */}
        <Box id="tempMap" sx={{ display: "none" }} />
      </Box>
      <ModalMasked
        open={openModal}
        close={toggleModal}
        header={"United States of America"}
        showX={false}
        body={<ContinentalUSPopup close={dismissModal} />}
      />
    </div>
  );
};

export { MapWrapper };

const ContinentalUSPopup = (props) => (
  <>
    <div className="information-container" style={{ margin: "auto", fontSize: "1.1em" }}>
      <ContinentalUSMessage />
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: 10,
        gap: ".7em",
      }}
    >
      <Button label={"Stay here"} onClick={() => props.close()} />
      <Button
        className={"active"}
        label={"Go to COMET-Planner"}
        onClick={() => {
          window.open("http://comet-planner.com", "_blank");
        }}
      />
    </div>
  </>
);

const LocationPin = ({ showDataWarning, color, missingDataMessage }) => (
  <div className="maps-location-pin">
    {showDataWarning && missingDataMessage}
    <span
      style={{
        width: "40px",
        height: "40px",
        marginTop: "-55px",
        marginLeft: "-39px",
        position: "absolute",
        top: "50%",
        left: "50%",
        color: color || "red",
        pointerEvents: "none",
        zIndex: 1,
      }}
    >
      <LocationPinSvg color01="var(--color-2-100)" color02="var(--color-2-400)" />
    </span>

    {/* <LocationOnIconMui
      id="map-center-marker"
      sx={{
        width: "40px",
        height: "40px",
        marginTop: "-55px",
        marginLeft: "-39px",
        position: "absolute",
        top: "50%",
        left: "50%",
        color: color || "red",
        pointerEvents: "none",
        zIndex: 1,
        display: "none",
      }}
    /> */}
  </div>
);

export const CrossHairs = () => (
  <>
    <div
      id="vertLine"
      style={{
        width: "1px",
        height: "100%",
        position: "absolute",
        left: "50%",
        border: "1px solid red",
        zIndex: "1",
      }}
    />
    <div
      id="horizLine"
      style={{
        height: "1px",
        width: "100%",
        position: "absolute",
        top: "50%",
        border: "1px solid red",
        zIndex: "1",
      }}
    />
  </>
);

const LocationPinSvg = ({ color01, color02 }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 256 256"
    height="2.5em"
    width="2.5em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color01}
      opacity="1"
      d="M128,24a80,80,0,0,0-80,80c0,72,80,128,80,128s80-56,80-128A80,80,0,0,0,128,24Zm0,112a32,32,0,1,1,32-32A32,32,0,0,1,128,136Z"
    ></path>
    <path
      fill={color02}
      // opacity=".2"
      d="M128,64a40,40,0,1,0,40,40A40,40,0,0,0,128,64Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,128,128Zm0-112a88.1,88.1,0,0,0-88,88c0,31.4,14.51,64.68,42,96.25a254.19,254.19,0,0,0,41.45,38.3,8,8,0,0,0,9.18,0A254.19,254.19,0,0,0,174,200.25c27.45-31.57,42-64.85,42-96.25A88.1,88.1,0,0,0,128,16Zm0,206c-16.53-13-72-60.75-72-118a72,72,0,0,1,144,0C200,161.23,144.53,209,128,222Z"
    ></path>
  </svg>
);

const WarningInfoSlide = ({ content, slideIn, containerRef }) => (
  <Slide
    direction="up"
    in={slideIn}
    container={containerRef}
    sx={{
      position: "absolute",
      bottom: "10px",
      bottom: "35px",
      left: "8px",
      zIndex: 1,
      maxWidth: "350px",
    }}
  >
    {content}
  </Slide>
);
