import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: [],
    selectedId: null,
    farmTypeId: null,
};

export const baselineSlice = createSlice({
    name: "baseline",
    initialState: initialState,
    reducers: {
        receiveBaselineList: (state, action) => {
            state.list = action.payload;
        },
        setBaseline: (state, action) => {
            state.selectedId = action.payload;
        },
        setFarmType: (state, action) => {
            state.farmTypeId = action.payload;
        },
    },
});

export const { receiveBaselineList, setBaseline, setFarmType } = baselineSlice.actions;

export const baselineReducer = baselineSlice.reducer;
