import { useDispatch, useSelector } from "react-redux";

//mui components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

// theme styles
import componentStyles from "../assets/theme/views/admin/dashboard.js";

//redux
import { getMap } from "../store/selectors.js";
import { toggleSelectedLayer } from "../reducers/map.js";
import { MAPLAYER_DEFAULT_OPACITY } from "../constants/mapLayers.js";

const useStyles = makeStyles()(componentStyles);

function MapLegend(props) {
  const { classes } = useStyles();

  const map = useSelector(getMap);
  const dispatch = useDispatch();
  const layer = window.globalPlannerMap
    .getAllLayers()
    .find((l) => l.get("name") === props.layerName);

  const layerActive = () => {
    return map.selectedLayer === props.layerName;
  };

  const toggleLayer = () => {
    dispatch(toggleSelectedLayer(props.layerName));
  };

  const handleSliderChange = (event, newValue) => {
    layer.setOpacity(convertOpacity2Transparency(newValue) / 100.0);
  };

  return (
    <Accordion expanded={layerActive()}>
      <AccordionSummary onClick={toggleLayer}>
        <ListItem classes={{ root: classes.muiListItemRoot }}>
          <ListItemIcon classes={{ root: classes.muiListItemIconRoot }}>
            <Checkbox edge="start" checked={layerActive()} />
          </ListItemIcon>
          <ListItemText primary={props.layerName} />
        </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        {props.subtitle && <h5 style={{ margin: "0 0 1em 0" }}>{props.subtitle}</h5>}
        <Typography>Transparency</Typography>
        <Slider
          defaultValue={convertOpacity2Transparency(MAPLAYER_DEFAULT_OPACITY * 100)}
          aria-label="Transparency"
          valueLabelDisplay="auto"
          onChange={handleSliderChange}
          sx={{ maxWidth: "90%" }}
        />
        <List>
          {props.lookups.map((lookup) => {
            return lookup ? (
              <ListItem key={lookup.id} classes={{ root: classes.muiListItemRoot }}>
                <ListItemIcon classes={{ root: classes.muiListItemIconRoot }}>
                  <Box
                    sx={{
                      backgroundColor: props.colors[lookup.id],
                      border: "1px solid black",
                      width: "1em",
                      height: "1em",
                      marginRight: "1em",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={lookup.name} />
              </ListItem>
            ) : null;
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

export default MapLegend;

const convertOpacity2Transparency = (e) => {
  /**
   * @convertOpacity2Transparency assumes input and output as a percentage
   *
   */
  if (isNaN(e)) return 0;
  return Math.abs(100 - e);
};
