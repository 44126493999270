import React from 'react'
import './ToolTipSpan.css'
import iconDownload from '../Icon/svgs/icon-download-from-cloud.svg'
import iconTrash from '../Icon/svgs/trash-alt-regular.svg'

export const ButtonSlide = props => {
  return (
    <button
      className={`ax-axuserinput-axbtniconstretch ax-iconbtns-transition`}
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
    >
      <div className="ax-iconbtns-child-wrap">
        <label className="ax-iconbtns-transition desktop-only">{props.label}</label>
        <img
          alt=""
          src={props.img}
          width={props.imgWidth || '30rem'}
          style={{ margin: 0, padding: 0 }}
        />
      </div>
    </button>
  )
}

export const DownloadButton = props => {
  const { onClick, disabled, label, style } = props
  return (
    <ButtonSlide
      style={style}
      label={label || 'Download'}
      className={'ax-tooltipbutton-img'}
      img={iconDownload}
      onClick={onClick}
      disabled={disabled}
    />
  )
}

export const ClearSelectionButton = props => {
  const { onClick, disabled, label, style } = props
  return (
    <ButtonSlide
      style={style}
      label={label || 'Clear Selections'}
      className={'ax-tooltipbutton-img'}
      img={iconTrash}
      onClick={onClick}
      disabled={disabled}
    />
  )
}

export default ButtonSlide
